import React from "react";
import { Field, Form, Formik } from 'formik';
import Swal from "sweetalert2";
import FormWrapper from "../component/FormWrapper";
import ChatbotAvatarCard from "../component/ChatbotAvatarCard";
import Modal from 'react-modal';
import * as Yup from "yup";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40rem',
        minHeight: '39rem',
        maxHeight: '39rem',
        borderRadius: '1.5em',
        boxShadow: '0px 16px 32px 4px rgba(0, 0, 0, 0.15)',
        padding: '1.3em',
        background: "linear-gradient(120deg, #C7366B 10%, #9F33AC 50%, #8532D7 90%)"
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 999,
    },
};

const CHAT_BOT_OPTIONS = [
    { value: "Chairman", label: "Chairman", type: "founder" },
    { value: "Principal", label: "Principal", type: "founder" },
    { value: "Vice Principal", label: "Vice Principal", type: "founder" },
    { value: "Faculty 1", label: "Faculty 1", type: "faculties" },
    { value: "Faculty 2", label: "Faculty 2", type: "faculties" },
    { value: "Faculty 3", label: "Faculty 3", type: "faculties" },
    { value: "Faculty 4", label: "Faculty 4", type: "faculties" },
    { value: "Faculty 5", label: "Faculty 5", type: "faculties" },
    { value: "Avatar 1", label: "Avatar 1", type: "default" },
    { value: "Avatar 2", label: "Avatar 2", type: "default" },
    { value: "Avatar 3", label: "Avatar 3", type: "default" },
];

const CHAT_BOT_QUESTIONS_OPTIONS = [
    { value: "Our Social Media Presence", label: "Our Social Media Presence" },
    { value: "Achievements", label: "Achievements" },
    { value: "Infrastructure", label: "Infrastructure" },
    { value: "Meet Our Educators/faculties", label: "Meet Our Educators/faculties" },
    { value: "Testimonials", label: "Testimonials" },
    { value: "Admission dates", label: "Admission dates" },
    { value: "Fee structure details", label: "Fee structure details" },
    { value: "Contact Us", label: "Contact Us" },
];

const initialValues = {
    NAME: "",
    LOGO: "",
    GOOGLE_MAP_POSITION_NAME: "",
    GOOGLE_MAP_POSITION: "",
    INSTITUTE_TYPE: "",
    BOARD_TYPE: "",
    ADDON_PROGRAMS: [],
    FOUNDING_DATE: "",
    INSTRUCTION_MEDIUM: [],
    GRADE_LEVELS: [],
}

const validationSchema = Yup.object({

})

const inititalChatbotData = { value: "", label: "", type: "" };

const ChatBot = ({ handleNavigate, resetFormData, titleAndDesNotRequired, disableForm }: { handleNavigate, resetFormData?: any, titleAndDesNotRequired?: boolean, disableForm?: boolean }) => {

    const [showModal, setshowModal] = React.useState<{ display: boolean; ID: any; }>({ display: false, ID: undefined });

    const handleOnCardClick = (item) => setshowModal({ display: true, ID: item });

    const getModalStyles = () => {
        if (window.matchMedia('(max-width: 768px)').matches) {
            // Apply smaller styles for mobile and tablet
            return {
                ...customStyles,
                content: {
                    ...customStyles.content,
                    width: '90%', // Set to a percentage or auto
                },
            };
        }
        // Default styles for larger screens
        return customStyles;
    };

    const loadInitialData = () => {
        try {
            return JSON.parse(sessionStorage.getItem("Chatbot"));
        } catch (err) {
            return inititalChatbotData;
        }
    };

    return (
        <FormWrapper
            childern={<div className="relative">
                {!!showModal.display && <Modal
                    isOpen={showModal.display}
                    contentLabel="Example Modal"
                    style={getModalStyles()}
                >
                    <div className="space-y-4">
                        <div className="flex flex-col gap-2 justify-center items-center" style={{ color: 'white' }}>
                            <p className="text-lg font-bold bg-clip-text text-white text-center">
                                Your AI Chatbot Is Here!
                            </p>
                            <div className="w-full text-center">
                                <p className="text-xs font-thin text-white">
                                    Select the Options You Want Below and Let Him Guide You!
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-center"
                            style={{
                                background: 'rgba(255, 255, 255, 0.2)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                backdropFilter: 'blur(10px)',
                                borderRadius: '10px',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                            }}
                        >
                            <img src="/images/ChatBotAvatar.png" alt="ChatBotAvatar" className="w-[35%] object-cover rounded-xl" />
                        </div>
                        <div className="flex justify-center flex-wrap gap-4 p-4"
                            style={{
                                background: 'rgba(255, 255, 255, 0.2)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                backdropFilter: 'blur(10px)',
                                borderRadius: '10px',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                            }}
                        >
                            {CHAT_BOT_QUESTIONS_OPTIONS?.map((item, index) => <button type="button" key={"akslhdqwkjhebanmsdbasmdadw" + index} className="text-xs text-gray-800 bg-[white] py-2 px-3 rounded-xl">{item?.label}</button>)}
                        </div>
                        <div className="flex rounded-lg bg-[white] p-1 gap-2">
                            <img src="/images/SearchPurple.svg" alt="SearchPurple" className="w-7 object-cover rounded-xl" />
                            <input type="text" placeholder="Type your question here, and we’ll be happy to assist you!" className="p-2 flex-1 text-xs" />
                        </div>
                        <div className="flex justify-end">
                            <div className="flex gap-2">
                                <button
                                    type="button"
                                    className="text-purple-700 font-semibold bg-[white] py-2 px-4 rounded-lg hover:opacity-90"
                                    onClick={() => setshowModal({ display: false, ID: "" })}
                                >
                                    CLOSE
                                </button>
                                <button
                                    type="button"
                                    className="text-purple-700 font-semibold bg-[white] py-2 px-4 rounded-lg hover:opacity-90"
                                    onClick={() => resetFormData()}
                                >
                                    RESET
                                </button>
                                <button
                                    type="button"
                                    className="text-purple-700 font-semibold bg-[white] py-2 px-4 rounded-lg hover:opacity-90"
                                    onClick={() => {
                                        sessionStorage.setItem("Chatbot", JSON.stringify(showModal?.ID));
                                        handleNavigate();
                                    }}
                                >
                                    PROCEED
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>}
                <Formik
                    initialValues={initialValues}
                    onSubmit={values => {
                        // console.log("Form submitted with values: ", values);
                        // sessionStorage.setItem("Chatbot", JSON.stringify(values));
                        // Swal.fire({
                        //     title: "Don't Worry! Your progress is automatically saved!",
                        //     icon: "success",
                        //     confirmButtonText: "PROCEED",
                        // });
                    }}
                >
                    {({ values, handleChange, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className={`bg-onboardingBackground rounded-3xl text-white space-y-3 ${!!titleAndDesNotRequired ? "" : "p-6 border border-[2.5px] border-purple-gradientOBborder"}`}>
                                {!titleAndDesNotRequired && <div className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */>
                                    <p className="text-lg font-bold bg-clip-text  mb-2 text-center">
                                        Pick the Avatar That Tells Your Story!
                                    </p>
                                    <div className="w-full text-center">
                                        <p className="text-xs font-thin ">
                                            Turn Your Current Staff Members into Your School’s Brand Ambassadors
                                        </p>
                                    </div>
                                </div>}

                                <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-1">
                                    {/* Column 1 */}
                                    <div className="scrollableContainerOB min-h-[59vh] max-h-[60vh] grid grid-cols-3 gap-2 border border-solid border-purple-gradientOBborder rounded-lg p-2 overflow-auto">
                                        <div className="col-span-3">
                                            <p className="text-xs font-thin text-center">
                                                Let a Founder’s Avatar Be Your Personal Guide!
                                            </p>
                                        </div>
                                        {CHAT_BOT_OPTIONS?.filter(item => item.type === "founder")?.map((item, index) => <ChatbotAvatarCard disable={disableForm} key={"aksndkwhejkasdasdasd" + index} loadInitialData={loadInitialData} item={item} handleOnClick={() => handleOnCardClick(item)} />)}
                                    </div>

                                    {/* Column 2 */}
                                    <div className="scrollableContainerOB min-h-[59vh] max-h-[60vh] grid grid-cols-3 gap-2 border border-solid border-purple-gradientOBborder rounded-lg p-2 overflow-auto">
                                        <div className="col-span-3">
                                            <p className="text-xs font-thin text-center">
                                                Or It Can be Any of Your Faculties Too!
                                            </p>
                                        </div>
                                        {CHAT_BOT_OPTIONS?.filter(item => item.type === "faculties")?.map((item, index) => <ChatbotAvatarCard disable={disableForm} key={"aksndkwhejkasdasdasdasdasdas" + index} loadInitialData={loadInitialData} item={item} handleOnClick={() => handleOnCardClick(item)} />)}
                                    </div>

                                    {/* Column 3 */}
                                    <div className="scrollableContainerOB min-h-[59vh] max-h-[60vh] grid grid-cols-3 gap-2 border border-solid border-purple-gradientOBborder rounded-lg p-2 overflow-auto">
                                        <div className="col-span-3">
                                            <p className="text-xs font-thin text-center">
                                                Or Select From Our Default Avatars Right Here!
                                            </p>
                                        </div>
                                        {CHAT_BOT_OPTIONS?.filter(item => item.type === "default")?.map((item, index) => <ChatbotAvatarCard disable={disableForm} key={"aksndkwhejkasdasdasdasdasdasdasd" + index} loadInitialData={loadInitialData} item={item} handleOnClick={() => handleOnCardClick(item)} />)}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end items-center gap-4 mt-4 sm:justify-center">
                                {/* <button
                                    type="button"
                                    className="bg-gradient-to-r from-[#844B9F] to-[#A93483]  py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                                    onClick={() => resetForm()}
                                    style={{ color: 'white' }}
                                >
                                    RESET
                                </button> */}
                                {/* <button
                                    type="submit"
                                    className="bg-gradient-to-r from-[#844B9F] to-[#A93483]  py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                                    style={{ color: 'white' }}
                                >
                                    PROCEED
                                </button> */}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>}
            formHeading={undefined}
        />
    );
}

export default ChatBot;