import React from "react";
import FormWrapper from "../component/FormWrapper";
import ResetAndSubmit from "components/ResetAndSubmit";
import { Link } from "react-router-dom";
import LF from "../LandingForm/LF";
import Leaders from "pages/Onboarding/Leaders";
import Faculties from "../Faculties";
import Recognition from "../Recognition";
import CampusInfo from "../CampusInfo";
import ChatBot from "../ChatBot";
import Testimonials from "../Testimonials";
import Brochu from "../Brochures/Brochu";

const SIDEBAR = [
    { label: "Your Website", route: "/website", value: true, component: "" },
    { label: "School Info", route: "/onboarding", value: (sessionStorage.getItem("Institute")), id: "Institute", component: <LF handleNavigate={""} resetFormData={""} titleAndDesNotRequired={true} disableForm={true} /> },
    { label: "Founder's Info", route: "/onboarding/leaders", value: (sessionStorage.getItem("founders")), id: "founders", component: <Leaders handleNavigate={""} resetFormData={""} titleAndDesNotRequired={true} disableForm={true} /> },
    { label: "Teachers Info", route: "/onboarding/faculties", value: (sessionStorage.getItem("faculties")), id: "faculties", component: <Faculties handleNavigate={""} resetFormData={""} titleAndDesNotRequired={true} disableForm={true} /> },
    { label: "Recognitions", route: "/onboarding/recognition", value: (sessionStorage.getItem("recognitions")), id: "recognitions", component: <Recognition handleNavigate={""} resetFormData={""} titleAndDesNotRequired={true} disableForm={true} /> },
    { label: "Campus Info", route: "/onboarding/campusinfo", value: (sessionStorage.getItem("campus")), id: "campus", component: <CampusInfo handleNavigate={""} resetFormData={""} titleAndDesNotRequired={true} disableForm={true} /> },
    { label: "Chatbot", route: "/onboarding/chatbot", value: (sessionStorage.getItem("Chatbot")), id: "Chatbot", component: <ChatBot handleNavigate={""} resetFormData={""} titleAndDesNotRequired={true} disableForm={true} /> },
    { label: "Testimonials", route: "/onboarding/testimonials", value: (sessionStorage.getItem("testimonials")), id: "testimonials", component: <Testimonials handleNavigate={""} resetFormData={""} titleAndDesNotRequired={true} disableForm={true} /> },
    { label: "Branding", route: "/onboarding/brochure", value: (sessionStorage.getItem("materials")), id: "materials", component: <Brochu handleNavigate={""} resetFormData={""} titleAndDesNotRequired={true} disableForm={true} /> },
];

const Preview = ({ handleNavigate, resetFormData }: { handleNavigate, resetFormData?: any }) => {

    return (
        <FormWrapper
            childern={<div className="relative">
                <div className="bg-onboardingBackground rounded-3xl p-6 text-white space-y-3 border border-[2.5px] border-purple-gradientOBborder">
                    <div className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */>
                        <p className="text-[4vh] font-bold bg-clip-text text-white text-center">
                            Review and Confirm Your Data
                        </p>
                        <div className="w-full text-center">
                            <p className="text-[2vh] font-thin text-white">
                                Please review all the data carefully before submiting.
                            </p>
                        </div>
                    </div>
                    {SIDEBAR?.slice(1)?.map((item, index) => <React.Fragment key={"ajdbkjahdbasjdbasmdbasdjasdsad"+index}>
                        <div className="flex justify-between items-center bg-clip-text text-white border border-solid p-1 rounded-lg">
                            <p className="text-[3vh] font-bold ">{item?.label}</p>
                            <Link type="button" to={item?.route} className="text-[2vh] border border-[black] rounded-lg py-1 px-3">Edit</Link>
                        </div>
                        {item?.component}
                    </React.Fragment>)}
                </div>
                <ResetAndSubmit submitLabel="Submit" />
            </div>} formHeading={undefined} />
    );
}

export default Preview;