import React from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import TextArea from "components/TextArea";
import HorizontalFormWrapper from "components/HorizontalFormWrapper";
import FormCard from "components/FormCard";
import ResetAndSubmit from "components/ResetAndSubmit";
import * as Yup from 'yup';

const QUALIFICATIONS_OPTIONS = [
    { value: "B.Ed", label: "B.Ed" },
    { value: "M.Ed", label: "M.Ed" },
    { value: "Ph.D", label: "Ph.D" },
    { value: "M.Phil", label: "M.Phil" },
    { value: "B.Sc", label: "B.Sc" },
    { value: "M.Sc", label: "M.Sc" },
    { value: "B.A", label: "B.A" },
    { value: "M.A", label: "M.A" },
    { value: "B.Tech", label: "B.Tech" },
    { value: "M.Tech", label: "M.Tech" },
    { value: "Others", label: "Others" },
];

const Founders = ({ handleNavigate, resetFormData, titleAndDesNotRequired, disableForm }: { handleNavigate, resetFormData?: any, titleAndDesNotRequired?: boolean, disableForm?: boolean }) => {
    const maxForms = 5;
    const emptyLeaderData = {
        INSTITUTE_LEADER_IMAGE: "",
        INSTITUTE_LEADER_NAME: "",
        INSTITUTE_LEADER_DESIGNATION: "",
        INSTITUTE_LEADER_QUALIFICATION: "",
        INSTITUTE_LEADER_EXPERIENCE: "",
        INSTITUTE_LEADER_DESCRIPTION: "",
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(sessionStorage.getItem("founders")) || [];
        return savedData.length ? savedData : [emptyLeaderData];
    };

    const validationSchema = Yup.object({
        founders: Yup.array().of(
            Yup.object({
                // INSTITUTE_LEADER_IMAGE: Yup.string().required("Institute leader's image is required"),
                INSTITUTE_LEADER_NAME: Yup.string().required("Institute leader's name is required"),
                INSTITUTE_LEADER_DESIGNATION: Yup.string().required("Institute leader's designation is required"),
                INSTITUTE_LEADER_QUALIFICATION: Yup.string().required("Institute leader's qualification is required"),
                INSTITUTE_LEADER_EXPERIENCE: Yup.number().required("Institute leader's experience is required"),
                // INSTITUTE_LEADER_DESCRIPTION: Yup.string().required("Required"),
            })
        ),
    });

    return (
        <FormWrapper
            childern={
                <Formik
                    initialValues={{ founders: loadInitialData() }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log("values.founders ", values.founders)
                        sessionStorage.setItem("founders", JSON.stringify(values.founders));
                        handleNavigate()
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit} className="flex flex-col gap-1 w-full relative">

                            {/* Insert Button Positioned Above the Forms */}
                            {/* {values.founders.length < maxForms && (
                                <div className="absolute top-2 right-2 z-10">
                                    <FieldArray name="founders">
                                        {({ push }) => (
                                            <button
                                                type="button"
                                                onClick={() => push(emptyLeaderData)}
                                                className="text-slate-700 font-bold text-[1vw] rounded-2xl border w-8 h-8 flex items-center justify-center hover:bg-slate-300 bg-[white]"
                                            >
                                                +
                                            </button>
                                        )}
                                    </FieldArray>
                                </div>
                            )} */}

                            <div className={`bg-onboardingBackground rounded-3xl text-white space-y-3 ${!!titleAndDesNotRequired ? "" : "p-6 border border-[2.5px] border-purple-gradientOBborder"}`}>
                                {!titleAndDesNotRequired && <div className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */>
                                    <div className="mx-2 w-[80%] text-center">
                                        <p className="text-sm font-bold mb-0.7">
                                            Founders/Leaders - The visionaries who shape the future
                                        </p>
                                        <p className="text-xs font-thin">
                                            Upload Chairman, Principal and Vice principal photos who guides students to become
                                            successful citizens.
                                        </p>
                                    </div>
                                    <p className="text-xs italic text-gray-500 font-thin" /* style={{color: "white"}} */>
                                        (Maximum 5 Leaders Details)
                                    </p>
                                </div>}



                                <div className="rounded-2xl my-1 mx-1">
                                    <FieldArray name="founders">
                                        {({ push, remove }) => (
                                            <div className="w-full">
                                                <HorizontalFormWrapper
                                                    cardSpacing={30}
                                                    showGhostCard={true}
                                                    onGhostCardClick={() => push(emptyLeaderData)}
                                                    maxItems={maxForms}
                                                    currentItemsCount={values.founders.length}
                                                    disable={disableForm}
                                                >
                                                    {values.founders.map((leaderElement, index) => (
                                                        <FormCard
                                                            key={index}
                                                            onDelete={values.founders.length > 1 ? () => remove(index) : undefined}
                                                        >
                                                            <Field name={`founders.${index}.INSTITUTE_LEADER_IMAGE`}>
                                                                {({ field, form }) => (
                                                                    <ImageUpload
                                                                        disable={disableForm}
                                                                        labelClassNames="text-center font-bold text-[0.8vw] pb-2"
                                                                        imageValue={field.value}
                                                                        onChange={(item) => {
                                                                            form.setFieldValue(`founders.${index}.INSTITUTE_LEADER_IMAGE`, item);
                                                                        }}
                                                                        wantHDOption
                                                                        containerHeight="h-8"
                                                                    />
                                                                )}
                                                            </Field>
                                                            <ErrorMessage name={`founders.${index}.INSTITUTE_LEADER_IMAGE`} component="div" className="text-red-500 text-xs mt-1" />

                                                            <Input
                                                                disable={disableForm}
                                                                label="What's Their Name?"
                                                                name={`founders.${index}.INSTITUTE_LEADER_NAME`}
                                                                type="text"
                                                                placeHolder="Enter their name here"

                                                                customHeight="27px"
                                                            />

                                                            <Input
                                                                disable={disableForm}
                                                                label="What's Their Designation?"
                                                                name={`founders.${index}.INSTITUTE_LEADER_DESIGNATION`}
                                                                type="text"
                                                                placeHolder="e.g., Chairman, Principal or vice principal"

                                                                customHeight="27px"
                                                            />

                                                            <Dropdown
                                                                disable={disableForm}
                                                                label="Their Qualification"
                                                                name={`founders.${index}.INSTITUTE_LEADER_QUALIFICATION`}
                                                                placeHolder="Select Qualification"
                                                                options={QUALIFICATIONS_OPTIONS}

                                                                customHeight="32px"
                                                            />

                                                            <Input
                                                                disable={disableForm}
                                                                label="Years of Experience"
                                                                name={`founders.${index}.INSTITUTE_LEADER_EXPERIENCE`}
                                                                type="number"
                                                                placeHolder="Enter years of experience"

                                                                customHeight="27px"
                                                            />

                                                            <TextArea
                                                                disabled={disableForm}
                                                                label="A Few Words About Them"
                                                                name={`founders.${index}.INSTITUTE_LEADER_DESCRIPTION`}
                                                                placeHolder="Optional"
                                                                customHeight="27px"
                                                                autoGenerate={true}
                                                            />
                                                        </FormCard>
                                                    ))}
                                                </HorizontalFormWrapper>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>

                            {/* Buttons Section */}
                            {!disableForm && <ResetAndSubmit
                                resetForm={() => {
                                    resetForm();
                                    resetFormData();
                                }}
                            />}
                        </Form>
                    )}
                </Formik>
            }
            formHeading={undefined}
        />
    );
};

export default Founders;