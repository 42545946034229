import React from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import TextArea from "components/TextArea";
import HorizontalFormWrapper from "components/HorizontalFormWrapper";
import FormCard from "components/FormCard";
import ResetAndSubmit from "components/ResetAndSubmit";
import * as Yup from "yup";

const SUBJECT_OPTIONS = [
    { value: "Telugu", label: "Telugu" },
    { value: "Math", label: "Math" },
    { value: "English", label: "English" },
    { value: "Science", label: "Science" },
    { value: "Social", label: "Social" },
    { value: "Hindi", label: "Hindi" },
    { value: "Others", label: "Others" },
];

const QUALIFICATIONS_OPTIONS = [
    { value: "B.Ed", label: "B.Ed" },
    { value: "M.Ed", label: "M.Ed" },
    { value: "Ph.D", label: "Ph.D" },
    { value: "M.Phil", label: "M.Phil" },
    { value: "Others", label: "Others" },
];

const Teachers = ({ handleNavigate, resetFormData }: { handleNavigate, resetFormData?: any }) => {
    const maxForms = 5;
    const emptyTeachersData = {
        TEACHER_IMAGE: "",
        TEACHER_NAME: "",
        TEACHER_SUBJECT: "",
        TEACHER_QUALIFICATION: "",
        TEACHER_EXPERIENCE: "",
        TEACHER_DESCRIPTION: "",
    };

    const initialValues = () => {
        const savedData = JSON.parse(sessionStorage.getItem("teachers")) || [];
        return savedData.length ? savedData : [emptyTeachersData];
    };

    const validationSchema=Yup.object({
        teachers:Yup.array().of(Yup.object({
            // TEACHER_IMAGE:Yup.string().required("Teacher image required"),
            TEACHER_NAME:Yup.string().required("Teacher name is required"),
            TEACHER_SUBJECT:Yup.string().required("Teacher subject is required"),
            TEACHER_QUALIFICATION:Yup.string().required("Teacher qualification is required"),
            TEACHER_EXPERIENCE:Yup.string().required("Teacher experience is required"),
            // TEACHER_DESCRIPTION:Yup.string().required("Required"),
        }))
    })

    return (
        <FormWrapper
            childern={
                <Formik
                    initialValues={{ teachers: initialValues() }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        // console.log("values.teachers ",values.teachers)
                        sessionStorage.setItem("teachers", JSON.stringify(values.teachers));
                        handleNavigate()
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit} className="flex flex-col gap-1 w-full relative">

                            {/* {values.teachers.length < maxForms && (
                                <div className="absolute top-2 right-2 z-10">
                                    <FieldArray name="teachers">
                                        {({ push }) => (
                                            <button
                                                type="button"
                                                onClick={() => push(emptyTeachersData)}
                                                className="text-slate-700 font-bold text-[1vw] rounded-2xl border w-8 h-8 flex items-center justify-center hover:bg-slate-300 bg-[white]"
                                            >
                                                +
                                            </button>
                                        )}
                                    </FieldArray>
                                </div>
                            )} */}

                            <div className="bg-onboardingBackground rounded-2xl p-1.5">
                                <div className="flex flex-col gap-1 justify-center items-center text-white" style={{ color: "white" }}>
                                    <div className="mx-2 w-[80%] text-center">
                                        <p className="text-sm font-bold mb-0.7">
                                            Let's Show Our Heroes!
                                        </p>
                                        <p className="text-xs font-thin">
                                            Upload your teachers' photos and showcase the inspiring faces behind the learning.
                                        </p>
                                    </div>
                                    <p className="text-xs italic text-gray-500 font-thin" style={{ color: "white" }}>
                                        (Maximum 5 Faculty Details)
                                    </p>
                                </div>




                                <div className="rounded-2xl my-1 mx-1">
                                    <FieldArray name="teachers">
                                        {({ push, remove }) => (
                                            <div className="w-full">
                                                <HorizontalFormWrapper
                                                    cardSpacing={30}
                                                    showGhostCard={true}
                                                    onGhostCardClick={() => push(emptyTeachersData)}
                                                    maxItems={maxForms}
                                                    currentItemsCount={values.teachers.length}
                                                >
                                                    {values.teachers.map((teacherElement, index) => (
                                                        <FormCard
                                                            key={index}
                                                            onDelete={values.teachers.length > 1 ? () => remove(index) : undefined}
                                                        >
                                                            <Field name={`teachers.${index}.TEACHER_IMAGE`}>
                                                                {({ field, form }) => (
                                                                    <ImageUpload
                                                                        labelClassNames="text-center font-bold text-[0.8vw] pb-2"
                                                                        imageValue={field.value}
                                                                        onChange={(item) => {
                                                                            form.setFieldValue(`teachers.${index}.TEACHER_IMAGE`, item);
                                                                        }}
                                                                        wantHDOption
                                                                        containerHeight="h-6"
                                                                    />
                                                                )}
                                                            </Field>
                                                            <ErrorMessage name={`teachers.${index}.TEACHER_IMAGE`} component="div" className="text-red-500 text-xs mt-1" />

                                                            <Input
                                                                label="What's Their Name?"
                                                                name={`teachers.${index}.TEACHER_NAME`}
                                                                type="text"
                                                                placeHolder="Enter Their Name Here"

                                                                customHeight="26px"
                                                            />

                                                            <Dropdown
                                                                label="What Subject Do They Teach?"
                                                                name={`teachers.${index}.TEACHER_SUBJECT`}
                                                                placeHolder="Select Subject"
                                                                options={SUBJECT_OPTIONS}

                                                                customHeight="31px"
                                                            />

                                                            <Dropdown
                                                                label="Their Qualification"
                                                                name={`teachers.${index}.TEACHER_QUALIFICATION`}
                                                                placeHolder="Select Qualification"
                                                                options={QUALIFICATIONS_OPTIONS}

                                                                customHeight="31px"
                                                            />

                                                            <Input
                                                                label="Years of Experience"
                                                                name={`teachers.${index}.TEACHER_EXPERIENCE`}
                                                                type="text"
                                                                placeHolder="e.g., 15 years"

                                                                customHeight="26px"
                                                            />

                                                            <TextArea
                                                                label="A Few Words About Them!"
                                                                name={`teachers.${index}.TEACHER_DESCRIPTION`}
                                                                placeHolder="Optional"
                                                                customHeight="40px"
                                                                autoGenerate={true}
                                                            />
                                                        </FormCard>
                                                    ))}
                                                </HorizontalFormWrapper>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>

                            {/* Buttons Section */}
                            <ResetAndSubmit
                                resetForm={() => {
                                    resetForm();
                                    resetFormData();
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            }
            formHeading={undefined}
        />
    );
};

export default Teachers;