import React from "react";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import { GoogleMaps } from "components/GoogleMaps";
import Swal from "sweetalert2";
import FormWrapper from "../component/FormWrapper";
import ResetAndSubmit from "components/ResetAndSubmit";
import LottieSpinner, { showAutoSaveNotification } from "components/LottieSpinner";
import ReactDOM from "react-dom/client";
import * as Yup from "yup"

const INSTITUTE_OPTIONS = [
    { value: "School", label: "School" },
    { value: "College", label: "College" },
    { value: "Degree College", label: "Degree College" },
    { value: "PG College", label: "PG College" },
    { value: "Others", label: "Others" },
];

const BOARD_TYPE = [
    { value: "CBSE", label: "CBSE" },
    { value: "ICSE", label: "ICSE" },
    { value: "State Board", label: "State Board" },
    { value: "Others", label: "Others" },
];

const ADDON_PROGRAMS = [
    { value: "CA", label: "CA" },
    { value: "ICWA", label: "ICWA" },
    { value: "CS", label: "CS" },
    { value: "NDA", label: "NDA" },
    { value: "AIMS", label: "AIMS" },
    { value: "Nalsar", label: "Nalsar" },
];

const INSTRUCTION_MEDIUM = [
    { value: "ENGLISH", label: "ENGLISH" },
    { value: "TELUGU", label: "TELUGU" }
];

const GRADE_LEVELS = [
    { value: "Grade 1", label: "Grade 1" },
    { value: "Grade 2", label: "Grade 2" },
    { value: "Grade 3", label: "Grade 3" },
    { value: "Grade 4", label: "Grade 4" },
    { value: "Grade 5", label: "Grade 5" },
    { value: "Grade 6", label: "Grade 6" },
    { value: "Grade 7", label: "Grade 7" },
    { value: "Grade 8", label: "Grade 8" },
    { value: "Grade 9", label: "Grade 9" },
    { value: "Grade 10", label: "Grade 10" },
    { value: "Grade 11", label: "Grade 11" },
];

const initialValues = {
    NAME: "",
    LOGO: "",
    GOOGLE_MAP_POSITION_NAME: "",
    GOOGLE_MAP_POSITION: "",
    INSTITUTE_TYPE: "",
    BOARD_TYPE: "",
    ADDON_PROGRAMS: [],
    FOUNDING_DATE: "",
    INSTRUCTION_MEDIUM: [],
    GRADE_LEVELS: [],
}

const validationSchema = Yup.object({
    NAME: Yup.string().required("Institute name is Required"),
    // LOGO: Yup.string().required("Institute logo is Required"),
    GOOGLE_MAP_POSITION_NAME: Yup.string().required("Institute location is Required"),
    // GOOGLE_MAP_POSITION: Yup.object().required("Required"),
    INSTITUTE_TYPE: Yup.string().required(" Institute type is Required"),
    BOARD_TYPE: Yup.string().required("Board type is Required"),
    ADDON_PROGRAMS: Yup.array().min(1, "Add on programs is Required"),
    FOUNDING_DATE: Yup.string().required("Institute founding date is Required"),
    INSTRUCTION_MEDIUM: Yup.array().min(1, "Institute medium is Required"),
    GRADE_LEVELS: Yup.array().min(1, "Atleast a grade is Required"),
});

const LF = ({ handleNavigate, resetFormData, titleAndDesNotRequired, disableForm }: { handleNavigate, resetFormData?: any, titleAndDesNotRequired?: boolean, disableForm?: boolean }) => {

    const loadInitialData = () => {
        try {
            return JSON.parse(sessionStorage.getItem("Institute")) || initialValues;
        } catch (err) {
            return initialValues;
        }
    };

    return (
        <FormWrapper
            childern={<div className="relative">

                <Formik
                    initialValues={loadInitialData()}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        console.log("Form submitted with values: ", values);
                        sessionStorage.setItem("Institute", JSON.stringify(values));

                        Swal.fire({
                            html: `
                                <div class="flex flex-col items-center p-6 bg-[#844B9F] text-[white] rounded-lg overflow-hidden">
                                    <div class="mb-4">
                                        <h2 class="text-2xl font-semibold mb-2">And..Don't Worry!</h2>
                                        <p class="text-lg">Your progress is automatically saved!</p>
                                    </div>
                                    <div id="lottie-spinner"></div>
                                </div>
                            `,
                            showConfirmButton: false,
                            background: '#844B9F',
                            customClass: {
                                popup: 'swal2-no-border'
                            },
                            showClass: {
                                popup: 'animate__animated animate__fadeIn'
                            },
                            didOpen: () => {
                                // Mount the Lottie spinner
                                const spinnerContainer = document.getElementById('lottie-spinner');
                                if (spinnerContainer) {
                                    const root = ReactDOM.createRoot(spinnerContainer);
                                    root.render(<LottieSpinner />);
                                }

                                // Auto close after 2 seconds
                                setTimeout(() => {
                                    Swal.close();
                                    handleNavigate();
                                }, 2000);
                            }
                        });
                    }}
                >
                    {({ values, handleChange, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <div className={`bg-onboardingBackground rounded-3xl text-white space-y-3 ${!!titleAndDesNotRequired ? "" : "p-6 border border-[2.5px] border-purple-gradientOBborder"}`}>
                                {!titleAndDesNotRequired && <div className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */>
                                    <p className="text-[4vh] font-bold bg-clip-text text-white text-center">
                                        Let's Build a Bold Digital Impression!
                                    </p>
                                    <div className="w-full text-center">
                                        <p className="text-[2vh] font-thin text-white">
                                            Share Vital Details – Build a Strong Foundation for Your Online Presence
                                        </p>
                                    </div>
                                </div>}

                                <div className="grid grid-cols-3 gap-12 sm:grid-cols-1 md:grid-cols-1">
                                    {/* Column 1 */}
                                    <div className="flex flex-col gap-2 justify-between">
                                        <Input
                                            disable={disableForm}
                                            name="NAME"
                                            backgroundColor="white"
                                            label="Institution Name"
                                            placeHolder="Enter your name here"
                                            labelClassNames="text-[2vh]"
                                            customStyle={{ padding: "2vh", fontSize: "2vh" }}
                                            required />
                                        <div className="w-full h-auto py-[1.8px]">
                                            <Field name="LOGO">
                                                {({ field, form }) => (
                                                    <ImageUpload
                                                        disable={disableForm}
                                                        label="Logo"
                                                        // containerHeight="h-1/3"
                                                        labelClassNames="text-[2vh]"
                                                        containerHeight={{
                                                            width: '7vh',
                                                            height: '7vh',
                                                        }}
                                                        imageValue={field.value}
                                                        onChange={item => {
                                                            form.setFieldValue('LOGO', item);
                                                        }} />
                                                )}
                                            </Field>
                                            <ErrorMessage name="LOGO" component="div" className="text-red-500 text-xs mt-1" />
                                        </div>
                                        <Input
                                            disable={disableForm}
                                            name="FOUNDING_DATE"
                                            type="date"
                                            backgroundColor="white"
                                            label="Founding Date"
                                            placeHolder="DD/MM/YY"
                                            labelClassNames="text-[2vh]"
                                            customStyle={{ padding: "2vh", fontSize: "2vh" }}
                                            required />
                                        <Dropdown
                                            disable={disableForm}
                                            name="INSTRUCTION_MEDIUM"
                                            label="Medium of Instruction"
                                            placeHolder="Select Medium"
                                            required
                                            isMulti
                                            customHeight=""
                                            labelClassNames="text-[2vh]"
                                            controlCustomStyle={{ height: "auto", fontSize: "1.8vh" }}
                                            placeholderCustomStyle={{ padding: "1vh" }}
                                            options={INSTRUCTION_MEDIUM} />
                                    </div>

                                    {/* Column 2 */}
                                    <div className="flex flex-col gap-2 justify-between">
                                        {/* <Input
                                            mapField
                                            name="GOOGLE_MAP_POSITION_NAME"
                                            mapName="GOOGLE_MAP_POSITION"
                                            backgroundColor="white"
                                            label="Location"
                                            placeHolder="Enter location"
                                        /> */}
                                        <Input
                                            disable={disableForm}
                                            mapField
                                            name="GOOGLE_MAP_POSITION_NAME"
                                            mapName="GOOGLE_MAP_POSITION"
                                            backgroundColor="white"
                                            label="Location"
                                            placeHolder="Enter location"
                                            labelClassNames="text-[2vh]"
                                            customStyle={{ padding: "2vh", fontSize: "2vh" }}
                                        />

                                        <Dropdown
                                            disable={disableForm}
                                            name="INSTITUTE_TYPE"
                                            label="Institution Type"
                                            placeHolder="Pick Institution Type"
                                            customHeight=""
                                            labelClassNames="text-[2vh]"
                                            controlCustomStyle={{ height: "auto", fontSize: "1.8vh" }}
                                            placeholderCustomStyle={{ padding: "1vh" }}
                                            options={INSTITUTE_OPTIONS} />
                                        <Dropdown
                                            disable={disableForm}
                                            name="GRADE_LEVELS"
                                            label="Grade/Class Levels"
                                            placeHolder="Pick Grades"
                                            required
                                            isMulti
                                            customHeight=""
                                            labelClassNames="text-[2vh]"
                                            controlCustomStyle={{ height: "auto", fontSize: "1.8vh" }}
                                            placeholderCustomStyle={{ padding: "1vh" }}
                                            options={GRADE_LEVELS} />
                                        <Dropdown
                                            disable={disableForm}
                                            name="BOARD_TYPE"
                                            label="Board Type"
                                            placeHolder="Pick Board Type"
                                            customHeight=""
                                            labelClassNames="text-[2vh]"
                                            controlCustomStyle={{ height: "auto", fontSize: "1.8vh" }}
                                            placeholderCustomStyle={{ padding: "1vh" }}
                                            options={BOARD_TYPE} />
                                        <Dropdown
                                            disable={disableForm}
                                            name="ADDON_PROGRAMS"
                                            label="AddOn Programs"
                                            placeHolder="Select Programs"
                                            isMulti
                                            customHeight=""
                                            labelClassNames="text-[2vh]"
                                            controlCustomStyle={{ height: "auto", fontSize: "1.8vh" }}
                                            placeholderCustomStyle={{ padding: "1vh" }}
                                            options={ADDON_PROGRAMS} />
                                    </div>

                                    {/* Column 3 */}
                                    <div className="flex flex-col gap-4 justify-end">
                                        <div className="flex flex-col rounded-2xl border border-solid border-black-900 overflow-hidden">
                                            <Field name="GOOGLE_MAP_POSITION">
                                                {({ field, form }) => (
                                                    <div className="h-[40vh]  overflow-hidden">
                                                        <GoogleMaps
                                                            disable={disableForm}
                                                            onLocationClick={e => {
                                                                form.setFieldValue('GOOGLE_MAP_POSITION', e);
                                                                form.setFieldValue('GOOGLE_MAP_POSITION_NAME', "");
                                                            }}
                                                            selectedLocation={field.value}
                                                            initialLocation={field.value}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                            <div className="bg-[white] py-4 px-12 text-center">
                                                <p className="text-sm text-gray-500">Click the Location Icon and Proceed with Pinning the Location   <br /> (Home,Work, Others)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!disableForm && <ResetAndSubmit
                                resetForm={() => {
                                    resetForm();
                                    resetFormData();
                                }}
                            />}
                        </Form>
                    )}
                </Formik>
            </div>} formHeading={undefined} />
    );
}

export default LF;