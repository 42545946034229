import React from "react";

export const FormCard = ({
  children,
  onDelete,
  showDelete = true,
  className = "",
  elevated = true,
  cardWidthClass = "",
  cardHeight = 'auto',
}) => {
  return (
    <div
      className={`
        bg-transparent
        ${elevated ? "shadow-md border border border-purple-gradientOBborder hover:shadow-xl" : "border border-[2px] border-purple-gradientOBborder"}
        overflow-hidden
        transition-all
        duration-300
        ${!!cardWidthClass ? cardWidthClass : "w-full lg:w-[300px]"}
        rounded-2xl
        ${className}
      `}
      style={{
        
        height: cardHeight,
        background: "rgba(255, 255, 255, 0.2)",
        backdropFilter: "blur(10px)",
        position: "relative",
      }}
    >
      {/* Body with responsive padding */}
      <div className="p-4 sm:p-3 lg:p-4">
        {children}
      </div>

      {/* X Button for Deleting Card */}
      {showDelete && onDelete && (
        <button
          type="button"
          onClick={onDelete}
          className="
            absolute 
            top-1 
            right-1 
            sm:top-2 
            sm:right-2 
            font-bold 
            text-sm 
            rounded-full 
            bg-[black]
            text-[white]
            border-black  
            transition-all 
            duration-300
            flex 
            items-center 
            justify-center
            w-6 
            h-6 
            sm:w-7 
            sm:h-7 
            lg:w-8 
            lg:h-8
          "
          aria-label="Close"
        >
          &times;
        </button>
      )}
    </div>
  );
};

export default FormCard;