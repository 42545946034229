import React from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import HorizontalFormWrapper from "components/HorizontalFormWrapper";
import FormCard from "components/FormCard";
import TextArea from "components/TextArea";
import ResetAndSubmit from "components/ResetAndSubmit";
import ImageHandler from "./ImageHandler";
import * as Yup from "yup";
import { ErrorCode } from "react-dropzone/.";

const IMAGE_OPTIONS = [
    { value: "Institution", label: "Institution" },
    { value: "Building", label: "Building" },
    { value: "Classroom", label: "Classroom" },
    { value: "Playground", label: "Playground" },
    { value: "Library", label: "Library" },
    { value: "Labs", label: "Labs" },
    { value: "Others", label: "Others" },
];

const validationSchema = Yup.object({
    campus: Yup.array().of(
        Yup.object({
            ABOUT_CAMPUS: Yup.string().required("About Campus is required"),
            IAMGE_FOR: Yup.string().required("Image For is required"),
            // CAMPUS_IMAGE: Yup.string().required("Campus Image is required"),
        })
    ),
});


const CampusInfo = ({ handleNavigate, resetFormData, titleAndDesNotRequired, disableForm }: { handleNavigate, resetFormData?: any, titleAndDesNotRequired?: boolean, disableForm?: boolean }) => {
    const maxForms = 5;

    const emptyCampusData = {
        ABOUT_CAMPUS: "",
        IAMGE_FOR: "",
        CAMPUS_IMAGE: "",
        SELECTED_CAMPUS_IMAGES: []
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(sessionStorage.getItem("campus")) || [];
        return savedData.length ? savedData : [emptyCampusData];
    };

   

    return (
        <FormWrapper
            childern={
                <Formik
                    initialValues={{ campus: loadInitialData() }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        // console.log("values.campus ", values.campus)
                        sessionStorage.setItem("campus", JSON.stringify(values.campus));
                        handleNavigate()
                    }}
                >
                    {({ values, handleSubmit, resetForm, setFieldValue }) => {
                        return <Form onSubmit={handleSubmit} className="flex flex-col gap-1 w-full relative">
                            {/* {values.campus.length < maxForms && (
                                <div className="absolute top-2 right-2 z-10">
                                    <FieldArray name="campus">
                                        {({ push }) => (
                                            <button
                                                type="button"
                                                onClick={() => push({...emptyCampusData, ABOUT_CAMPUS: values?.campus?.[0]?.ABOUT_CAMPUS})}
                                                className="text-slate-700 font-bold text-[1vw] rounded-2xl border w-8 h-8 flex items-center justify-center hover:bg-slate-300 bg-[white]"
                                            >
                                                +
                                            </button>
                                        )}
                                    </FieldArray>
                                </div>
                            )} */}
<div className={`bg-onboardingBackground rounded-3xl text-white space-y-3 ${!!titleAndDesNotRequired ? "" : "p-6 border border-[2.5px] border-purple-gradientOBborder"}`}>
                                {!titleAndDesNotRequired && <div className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */>
                                    <div className="mx-2 w-[80%] text-center" /* style={{ color: "white" }} */>
                                        <p className="text-sm font-bold mb-0.7">Showcase Your Institution’s Campus Excellence!</p>
                                        <p className="text-xs font-thin">
                                            Upload photos of your institution’s vibrant spaces – from classrooms to playgrounds – and let us highlight your institution’s environment with stunning visuals.
                                        </p>
                                    </div>
                                    <p className="text-xs italic  text-gray-500 font-thin" /* style={{ color: "white" }} */>
                                        (Maximum 5 Campus Details)
                                    </p>
                                </div>}

                                {/* Insert Button Positioned Above the Forms */}
                                {/* {values.campus.length < maxForms && (
                                {/* {values.campus.length < maxForms && (
                                    <div className="flex justify-end mb-1 mr-2 z-10">
                                        <FieldArray name="campus">
                                            {({ push }) => (
                                                <button
                                                    type="button"
                                                    onClick={() => push({ ...emptyCampusData, ABOUT_CAMPUS: values?.campus?.[0]?.ABOUT_CAMPUS })}
                                                    className="text-slate-700 font-bold text-[1vw] rounded-2xl border w-16 h-8 flex items-center justify-center hover:bg-slate-300 bg-[white]"
                                                >
                                                    Insert
                                                </button>
                                            )}
                                        </FieldArray>
                                    </div>
                                )} */}

                                <div className="flex flex-col justify-center items-center gap-1 rounded-2xl my-1 mx-1  overflow-auto">
                                    <FieldArray name="campus">
                                        {({ push, remove }) => (
                                            <div className="w-full">
                                                {/* For larger screens (Desktop or Tablet), use Horizontal FormWrapper */}
                                                <HorizontalFormWrapper
                                                    cardSpacing={30}
                                                    showGhostCard={true}
                                                    onGhostCardClick={() => push(emptyCampusData)}
                                                    maxItems={maxForms}
                                                    currentItemsCount={values.campus.length}
                                                    disable={disableForm}
                                                >
                                                    {values.campus.map((campusElement, index) => {
                                                        return <FormCard
                                                            key={index}
                                                            onDelete={values.campus.length > 1 ? () => remove(index) : undefined}
                                                        >
                                                            <div className="space-y-2">
                                                                <TextArea
                                                                    disabled={index > 0 || disableForm}
                                                                    rows={4}
                                                                    label="A Few Words About Them"
                                                                    labelClassNames="text-xs"
                                                                    name={`campus.${index}.ABOUT_CAMPUS`}
                                                                    placeHolder="AI will automatically generate a compelling description. If the content doesn’t meet your expectations, simply click the refresh icon to try again."
                                                                    autoGenerate={true}
                                                                />
                                                                <Dropdown
                                                    disable={disableForm}
                                                                    label="Give the Image, A Title"
                                                                    name={`campus.${index}.IAMGE_FOR`}
                                                                    placeHolder="Select Subjects"
                                                                    options={IMAGE_OPTIONS}

                                                                />
                                                                <Field name={`campus.${index}.CAMPUS_IMAGE`} >
                                                                    {({ field, form }) => (
                                                                        <ImageUpload
                                                                        disable={disableForm}
                                                                            labelClassNames="text-center font-bold text-[0.8vw]"
                                                                            imageValue={field.value}
                                                                            onChange={(item) => {
                                                                                form.setFieldValue(`campus.${index}.CAMPUS_IMAGE`, item);
                                                                                if (campusElement?.SELECTED_CAMPUS_IMAGES?.length) {
                                                                                    form.setFieldValue(`campus.${index}.SELECTED_CAMPUS_IMAGES`, [...campusElement?.SELECTED_CAMPUS_IMAGES, item]);
                                                                                } else {
                                                                                    form.setFieldValue(`campus.${index}.SELECTED_CAMPUS_IMAGES`, [item]);
                                                                                }
                                                                            }}
                                                                            // wantHDOption
                                                                            containerHeight="h-8"
                                                                            previewNotRequired={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <ImageHandler disable={disableForm} campusElement={campusElement} setCampusElement={element => setFieldValue(`campus.${index}.SELECTED_CAMPUS_IMAGES`, element)} />
                                                                {/* <div className="flex justify-center items-center gap-4">
                                                                    {[1, 2, 3]?.map((item, index) => !!campusElement?.SELECTED_CAMPUS_IMAGES?.[index] ? <img
                                                                        key={`campus.${index}.CAMPUS_IMAGE${index}`}
                                                                        src={URL.createObjectURL(campusElement?.SELECTED_CAMPUS_IMAGES?.[index])}
                                                                        alt="Uploaded"
                                                                        className="w-20 h-20 border border-solid rounded-lg object-contain"
                                                                    /> : <div className="w-20 h-20 border border-solid rounded-lg bg-gray-400"></div>)}
                                                                </div> */}
                                                                <ErrorMessage name={`campus.${index}.CAMPUS_IMAGE`} component="div" className="text-red-500 text-xs mt-1" />
                                                            </div>

                                                        </FormCard>
                                                    })}
                                                </HorizontalFormWrapper>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>

                            {/* Buttons Section */}
                           {!disableForm && <ResetAndSubmit
                                resetForm={() => {
                                    resetForm();
                                    resetFormData();
                                }}
                            />}
                        </Form>
                    }}
                </Formik>
            }
            formHeading={undefined}
        />
    );
};

export default CampusInfo;
