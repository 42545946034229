import React, { useEffect } from "react";
import { NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
// import LandingForm from "pages/Onboarding/LandingForm";
import Faculties from "pages/Onboarding/Faculties";
// import Infrastructure from "pages/Onboarding/Infrastructure";
import Recognition from "pages/Onboarding/Recognition";
import Testimonials from "pages/Onboarding/Testimonials";
import Leaders from "pages/Onboarding/Leaders";
// import Brochures from "pages/Onboarding/Brochures";
import Teachers from "pages/Onboarding/Teachers";
import ChatBot from "pages/Onboarding/ChatBot";
import CampusInfo from "pages/Onboarding/CampusInfo";
import LF from "pages/Onboarding/LandingForm/LF";
// import Modal from 'react-modal';
import Brochu from "pages/Onboarding/Brochures/Brochu";
import Preview from "pages/Onboarding/Preview";

// const customStyles = {
//     content: {
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)',
//         width: '95vw',
//         minHeight: '95vh',
//         maxHeight: '95vh',
//         borderRadius: '1.5em',
//         boxShadow: '0px 16px 32px 4px rgba(0, 0, 0, 0.15)',
//         // padding: '1.3em',
//         // background: "linear-gradient(120deg, #C7366B 10%, #9F33AC 50%, #8532D7 90%)"
//     },
//     overlay: {
//         background: 'rgba(0, 0, 0, 0.4)',
//         zIndex: 999,
//     },
// };

const SFR = () => {
    
    const [formKey, setFormKey] = React.useState(Date.now()); // Change key on reset
    
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigate = (preview?:boolean) => {
        if(preview) {
            navigate("/onboarding/preview");
        } else {
            navigate(SIDEBAR?.[SIDEBAR?.findIndex(item => item?.route === location?.pathname) + 1]?.route);
        }
    }

    const SIDEBAR = [
        { label: "Your Website", route: "/website", value: true },
        { label: "School Info", route: "/onboarding", value: (sessionStorage.getItem("Institute")), id: "Institute" },
        { label: "Founder's Info", route: "/onboarding/leaders", value: (sessionStorage.getItem("founders")), id: "founders" },
        { label: "Teachers Info", route: "/onboarding/faculties", value: (sessionStorage.getItem("faculties")), id: "faculties" },
        { label: "Recognitions", route: "/onboarding/recognition", value: (sessionStorage.getItem("recognitions")), id: "recognitions" },
        { label: "Campus Info", route: "/onboarding/campusinfo", value: (sessionStorage.getItem("campus")), id: "campus" },
        { label: "Chatbot", route: "/onboarding/chatbot", value: (sessionStorage.getItem("Chatbot")), id: "Chatbot" },
        { label: "Testimonials", route: "/onboarding/testimonials", value: (sessionStorage.getItem("testimonials")), id: "testimonials" },
        { label: "Branding", route: "/onboarding/brochure", value: (sessionStorage.getItem("materials")), id: "materials" },
    ];

    const renderNavLink = (route?) => {
        if (!!SIDEBAR?.find(item => route === item?.route)?.value) {
            return { value: `stepperItemOB completedOB ${location?.pathname === route ? "activeOB" : ""}`, clickable: true }
        } else if ((location?.pathname === route) && !SIDEBAR?.find(item => route === item?.route)?.value) {
            return { value: `stepperItemOB activeOB`, clickable: true }
        } else {
            return { value: `stepperItemOB`, clickable: false }
        }
    }

    const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, passedIndex) => {
        // console.log("event ",event);
        // console.log("passedIndex ",passedIndex);
        // console.log("current index data ",SIDEBAR?.slice(1)?.[passedIndex]);
        // console.log("previous index data ",SIDEBAR?.slice(1)?.[passedIndex-1]);
        // console.log("type checkking",typeof(SIDEBAR?.slice(1)?.[passedIndex-1]));
        if (typeof(SIDEBAR?.slice(1)?.[passedIndex-1]) === "object" && !SIDEBAR?.slice(1)?.[passedIndex-1]?.value) {
            event.preventDefault(); // Prevents navigation
        }
    };

    const resetFormData = () => {
        // navigate(SIDEBAR?.[SIDEBAR?.findIndex(item => item?.route === location?.pathname)]?.route)
        sessionStorage.removeItem(SIDEBAR?.find(item => item?.route === location?.pathname)?.id);
        setFormKey(formKey => Date.now());
    }

    useEffect(() => {
        if(!!location?.pathname) {
            const passedIndex = SIDEBAR?.slice(1)?.findIndex(item => item?.route === location?.pathname);
            const aValue = SIDEBAR?.slice(1)?.slice(0,passedIndex)?.filter(item => !item?.value)?.[0];
            // console.log("aValue ",aValue)
            if(typeof(aValue) === "object" && !aValue?.value) { 
                // navigate(SIDEBAR?.slice(1)?.[(SIDEBAR as any)?.findLastIndex(item => !!item?.value)]?.route)
                navigate(aValue?.route)
            }
        }
    },[location?.pathname])

    return (
        <div key={formKey} className="container mx-auto font-poppins text-gray-ob sm:px-4 lg:px-8">
            <nav className="flex w-full justify-between items-center bg-slate-100 p-1 sm:p-4">
                <div className="flex w-full items-center gap-2 sm:gap-4">
                    <NavLink to="/" style={{ width: '3.5vw' }}>
                        <img
                            className="w-[14vw] sm:w-[6vw] lg:w-[3vw] h-auto object-contain mx-auto"
                            src={"/images/Logo.svg"}
                            alt="SooMuch Logo"
                            loading={"lazy"}
                        />
                    </NavLink>
                    <p className="font-bold text-sm sm:text-lg md:text-xl">Onboarding Setup</p>
                </div>
            </nav>
            <div className="grid grid-cols-12 gap-x-4 gap-y-2 p-2">
                {/* Stepper Wrapper - Hidden on mobile and tablet, visible on larger screens */}
                {/* <div className="col-span-12"> Hidden on mobile and tablet */}
                <div className="col-span-12 sm:hidden md:hidden laptop:block">
                    <div className="stepperWrapperOB">
                        {SIDEBAR?.slice(1)?.map((item, index) => (
                            <NavLink onClick={e => handleNavClick(e, index)} end={item?.route === "/onboarding"} to={item?.route} key={"akjdilqwejhkdhasdasd" + index} className={renderNavLink(item?.route)?.value/* `stepperItemOB completedOB activeOB` */}>
                                <div className="stepCounterOB">
                                    <img
                                        className="w-5 h-5 object-contain mx-auto"
                                        src={"/images/WhiteTick.svg"}
                                        alt="SooMuch Logo"
                                        loading={"lazy"}
                                    />
                                </div>
                                <div className="text-xs text-center w-[70%]">{item?.label}</div>
                            </NavLink>
                        ))}
                    </div>
                </div>

                {/* Sidebar - Hidden on mobile and tablet, visible on larger screens */}
                {/* <div className="col-span-2 border border-gray-400 rounded-3xl overflow-auto p-4 sm:hidden md:hidden laptop:block"> 
                    <div className="flex flex-col gap-1">
                        {SIDEBAR?.map((item, index) => (
                            <NavLink end={item?.route === "/onboarding"} to={item?.route} key={"asdkajsdhwwnebsandasdasdwqeasd" + index} className={({ isActive }) => `border border-gray-400 py-2 px-4 text-center font-semibold text-sm ${isActive ? "gradientBGOB text-[white]" : "gradientTextOB"}`}>
                                {item?.label}
                            </NavLink>
                        ))}
                    </div>
                </div> */}

                {/* Main Content Area */}
                <div className="col-span-12 sm:col-span-12 md:col-span-12 rounded-3xl">
                    <Routes>
                        {/* <Route path="/onboarding" element={<LandingForm />} />                    //screen1 */}
                        <Route
                            index
                            element={<LF handleNavigate={handleNavigate} resetFormData={resetFormData} />}
                        />
                        <Route path="/leaders" element={<Leaders handleNavigate={handleNavigate} resetFormData={resetFormData} />} />                //screen2
                        {/* <Route path="/infrastructure" element={<Infrastructure handleNavigate={handleNavigate} />} />  //screen4 */}
                        <Route path="/faculties" element={<Faculties handleNavigate={handleNavigate} resetFormData={resetFormData} />} />            //screen5
                        <Route path="/recognition" element={<Recognition handleNavigate={handleNavigate} resetFormData={resetFormData} />} />        //screen6
                        <Route path="/testimonials" element={<Testimonials handleNavigate={handleNavigate} resetFormData={resetFormData} />} />      //screen7
                        <Route path="/brochure" element={<Brochu handleNavigate={handleNavigate} resetFormData={resetFormData} />} />             //screen9
                        <Route path="/teachers" element={<Teachers handleNavigate={handleNavigate} resetFormData={resetFormData} />} />             //screen10
                        <Route path="/chatbot" element={<ChatBot handleNavigate={handleNavigate} resetFormData={resetFormData} />} />             //screen9
                        <Route path="/campusinfo" element={<CampusInfo handleNavigate={handleNavigate} resetFormData={resetFormData} />} />
                        <Route path="/preview" element={<Preview handleNavigate={handleNavigate} resetFormData={resetFormData} />} />
                    </Routes>
                </div>
            </div>

        </div>
    );
};

export default SFR;
