import axios from "axios";
import { ErrorMessage, Field } from "formik";
import React, { useCallback } from "react";
import _ from "lodash";

const Input: ({ label, required, backgroundColor, name, type, placeHolder, customHeight }: {
    label?: string;
    required?: boolean;
    backgroundColor?: string;
    name: string;
    mapName?: string;
    type?: string;
    placeHolder?: string;
    customHeight?: string; // Define customHeight prop
    labelClassNames?: string;
    mapField?: boolean;
    customFontSize?: string;
    customStyle?: any;
    disable?: boolean;
}) => React.JSX.Element = ({
    label, required, backgroundColor, name, type, placeHolder, mapField, mapName, labelClassNames, customHeight, customFontSize, customStyle, disable // Include customHeight in props
}) => {
    const updateLocationFromAddress = async (enteredAddress, name, form) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    enteredAddress
                )}&key=${process.env.REACT_APP_GOOGLE_API_KEY!}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response?.data?.results?.length > 0) {
                const location = response?.data?.results?.[0]?.geometry?.location;
                const formattedAddress = response?.data?.results?.[0]?.formatted_address;
                form.setFieldValue(mapName, ({ lat: location.lat, lng: location.lng, address: formattedAddress }));
            }
        } catch (error) {
        }
    };

    const debouncedUpdateLocation = useCallback(
        _.debounce((item, mapName, form) => {
            updateLocationFromAddress(item, mapName, form);
        }, 500),
        [mapName, updateLocationFromAddress]
    );

    const handleChange = (event, form) => {
        const item = event.target.value;
        if (mapField && !!item) {
            debouncedUpdateLocation(item, mapName, form);
        }
        form.setFieldValue(name, item);
    };

    return (
        <div className="flex flex-col gap-1 w-full">
            {!!label && (
                <label
                    className={`block text-white text-xs ${labelClassNames || ""}`}
                    // style={{ color: 'white' }}
                >
                    {label} {!!required && <span className="text-red-700">*</span>}
                </label>
            )}
            <div
                style={{ backgroundColor: backgroundColor || "white" }}
                // className="w-full border border-solid border-black-900 rounded-lg py-[2.8px] px-2"
                className="w-full border border-solid border-black-900 rounded-lg py-[2.8px] px-2"
            >
                <Field name={name}>
                    {({ field, form, meta }) => (
                        <input
                            disabled={disable}
                            className="rounded-lg w-full px-2 text-xs md:text-sm placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent"
                            placeholder={placeHolder || ""}
                            style={{
                                backgroundColor: backgroundColor || "white",
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                fontSize: customFontSize || '0.75rem',
                                height: customHeight || '32px', // Use customHeight with default
                                ...(!!customStyle ? customStyle : {})
                            }}
                            type={type || "text"}
                            required={required}
                            value={field?.value?.address || field.value}
                            onChange={item => handleChange(item, form)}
                        />
                    )}
                </Field>
            </div>
            <ErrorMessage name={name} component="div" className="text-red-600 text-xs mt-1" />
        </div>
    );
}

export default Input;
