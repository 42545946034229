import React from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import Input from "components/Input";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import TextArea from "components/TextArea"; // Import the updated TextArea component
import HorizontalFormWrapper from "components/HorizontalFormWrapper";
import FormCard from "components/FormCard";
import Swal from "sweetalert2";
import ResetAndSubmit from "components/ResetAndSubmit";
import * as Yup from "yup";

const Testimonials = ({ handleNavigate, resetFormData, titleAndDesNotRequired, disableForm }: { handleNavigate, resetFormData?: any, titleAndDesNotRequired?: boolean, disableForm?: boolean }) => {
    const maxForms = 5;
    const emptyTestimonialData = {
        TESTIMONIALS_IMAGE: "",
        TESTIMONIALS_NAME: "",
        TESTIMONIALS_TESTIMONIAL: "",
        TESTIMONIALS_RATING: 0,
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(sessionStorage.getItem("testimonials")) || [];
        return savedData.length ? savedData : [emptyTestimonialData];
    };

    const validationSchema=Yup.object({
        testimonials: Yup.array().of(Yup.object({
            // TESTIMONIALS_IMAGE: Yup.string().required("Image is required"),
            TESTIMONIALS_NAME: Yup.string().required("Name is required"),
            TESTIMONIALS_TESTIMONIAL: Yup.string().required("Testimonial is required"),
        }))
    })

    return (
        <FormWrapper
            formHeading={undefined}
            childern={
                <Formik
                    initialValues={{ testimonials: loadInitialData() }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log("values.testimonials ",values.testimonials)
                        sessionStorage.setItem("testimonials", JSON.stringify(values.testimonials));
                        // Swal.fire({
                        //     title: "Testimonials saved successfully!",
                        //     icon: "success",
                        //     confirmButtonText: "Okay",
                        // });
                        handleNavigate();
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit} className="flex flex-col gap-1 w-full relative">


<div className={`bg-onboardingBackground rounded-3xl text-white space-y-3 ${!!titleAndDesNotRequired ? "" : "p-6 border border-[2.5px] border-purple-gradientOBborder"}`}>
                                {!titleAndDesNotRequired && <div className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */>
                                    <div className="mx-2 w-[80%] text-center">
                                        <p className="text-sm font-bold mb-0.7">
                                            Hear it from the Heart!
                                        </p>
                                        <p className="text-xs font-thin">
                                            Showcase the voices of parents who trust your institution. Highlight the testimonials that reflect your school's impact.
                                        </p>
                                    </div>
                                    <p className="text-xs italic text-gray-500 font-thin" /* style={{ color: "white" }} */>
                                        (Maximum 5 Testimonials)
                                    </p>
                                </div>}

                                <div className="rounded-2xl my-1 mx-1">
                                    <FieldArray name="testimonials">
                                        {({ push, remove }) => (
                                            <HorizontalFormWrapper
                                                cardSpacing={30}
                                                showGhostCard={true}
                                                onGhostCardClick={() => push(emptyTestimonialData)}
                                                maxItems={maxForms}
                                                currentItemsCount={values.testimonials.length}
                                                disable={disableForm}
                                            >
                                                {values.testimonials.map((testimonial, index) => (
                                                    <FormCard
                                                        key={index}
                                                        onDelete={values.testimonials.length > 1 ? () => remove(index) : undefined}
                                                    >
                                                        <Field name={`testimonials.${index}.TESTIMONIALS_IMAGE`}>
                                                            {({ field, form }) => (
                                                                <ImageUpload
                                                                disable={disableForm}
                                                                    labelClassNames="text-center font-bold text-[0.8vw] pb-2"
                                                                    imageValue={field.value}
                                                                    onChange={(item) => {
                                                                        form.setFieldValue(`testimonials.${index}.TESTIMONIALS_IMAGE`, item);
                                                                    }}
                                                                    wantHDOption
                                                                    containerHeight="h-8"
                                                                />
                                                            )}
                                                        </Field>
                                                        <ErrorMessage name={`testimonials.${index}.TESTIMONIALS_IMAGE`} component="div" className="text-red-500 text-xs mt-1" />


                                                        <Input
                                                    disable={disableForm}
                                                            label="Parent's Name"
                                                            name={`testimonials.${index}.TESTIMONIALS_NAME`}
                                                            type="text"
                                                            placeHolder="Enter Parent's name"

                                                        />

                                                        <TextArea
                                                    disabled={disableForm}
                                                            label="Share Their Testimonial"
                                                            name={`testimonials.${index}.TESTIMONIALS_TESTIMONIAL`}
                                                            placeHolder="e.g., I am incredibly pleased with the education my child is receiving."
                                                            autoGenerate={true}
                                                        />
                                                    </FormCard>
                                                ))}
                                            </HorizontalFormWrapper>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>
                            {!disableForm && <ResetAndSubmit
                                resetForm={() => {
                                    resetForm();
                                    resetFormData();
                                }}
                            />}
                        </Form>
                    )}
                </Formik>
            }
        />
    );
};

export default Testimonials;
