import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import Lottie from "lottie-react";
import DotAnimation from "assets/images/DotAnimation.json";

const TextArea: ({
    label,
    required,
    backgroundColor,
    name,
    placeHolder,
    rows,
    cols,
    customHeight,
    labelClassNames,
    disabled,
    autoGenerate,
}: {
    label?: string;
    required?: boolean;
    backgroundColor?: string;
    name: string;
    placeHolder?: string;
    rows?: number;
    cols?: number;
    customHeight?: string;
    disabled?: boolean;
    labelClassNames?: string;
    autoGenerate?: boolean;
}) => React.JSX.Element = ({
    label,
    required,
    backgroundColor,
    name,
    placeHolder,
    rows = 4,
    cols = 50,
    customHeight,
    labelClassNames,
    disabled,
    autoGenerate = false,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    // Function to generate random text
    const generateRandomText = () => {
        const words = ["Lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit"];
        let randomText = "";
        for (let i = 0; i < 10; i++) {
            randomText += words[Math.floor(Math.random() * words.length)] + " ";
        }
        return randomText.trim();
    };

    // Handle refresh button click
    const handleRefresh = (form) => {
        setIsLoading(true);
        setTimeout(() => {
            const randomText = generateRandomText();
            form.setFieldValue(name, randomText); 
            setIsLoading(false); 
        }, 2000); 
    };

    return (
        <div className="flex flex-col gap-2">
            {!!label && (
                <label
                    htmlFor={name}
                    style={{ fontSize: `12px` }}
                    className={`block ${labelClassNames || ""}`}
                >
                    {label} {!!required && <span className="text-red-500">*</span>}
                </label>
            )}
            <div
                style={{ backgroundColor: backgroundColor || "white" }}
                className={`w-full border border-solid border-black-900 rounded-2xl py-1 px-2 relative`}
            >
                {isLoading && (
                    <div
                        className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10"
                        style={{ height: customHeight || "auto" }}
                    >
                        <Lottie animationData={DotAnimation} style={{ width: 90, height: 100 }} />
                    </div>
                )}
                <Field name={name}>
                    {({ field, form }) => (
                        <div className="relative">
                            <textarea
                                disabled={disabled}
                                id={name}
                                className={`rounded-2xl w-full py-2 px-2 text-gray-800 text-sm sm:text-base md:text-lg ${!!disabled ? "cursor-not-allowed" : ""}`}
                                placeholder={placeHolder || ""}
                                rows={rows}
                                cols={cols}
                                style={{
                                    backgroundColor: backgroundColor || "white",
                                    height: customHeight || "auto",
                                    padding: `calc(${customHeight} / 8)`,
                                    fontSize: `12px`,
                                    resize: "none", // Disable the resize handle
                                }}
                                required={required}
                                value={field.value}
                                onChange={(event) => form.setFieldValue(name, event.target.value)}
                            />
                            {!disabled && autoGenerate && (
                                <button
                                    type="button"
                                    onClick={() => handleRefresh(form)}
                                    className="absolute top-1 right-1 p-1.5 m-1 bg-gray-400 rounded-full hover:bg-gray-300"
                                    style={{ zIndex: 1, top: customHeight ? `calc(${customHeight} / 2 - 16px)` : "0.25rem" }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            )}
                        </div>
                    )}
                </Field>
            </div>
            <ErrorMessage name={name} component="div" className="text-red-500 text-xs" />
        </div>
    );
};

export default TextArea;