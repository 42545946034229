import React, { useRef, useState, useEffect } from "react";

const HorizontalFormWrapper = ({
  children,
  cardSpacing = 16,
  showGhostCard = false,
  onGhostCardClick,
  maxItems,
  currentItemsCount,
  disable = false
}) => {
  const scrollContainerRef = useRef(null);
  const contentRef = useRef(null);
  const [deviceLayout, setDeviceLayout] = useState('desktop');
  const [showControls, setShowControls] = useState(false);
  const [cardDimensions, setCardDimensions] = useState({ width: 300, height: 425 });

  useEffect(() => {
    const handleResize = () => {
      setDeviceLayout(window.innerWidth < 1024 ? 'mobile' : 'desktop');
      
      // Get dimensions from the first FormCard
      if (contentRef.current) {
        const firstCard = contentRef.current.querySelector('.form-card');
        if (firstCard) {
          const { width, height } = firstCard.getBoundingClientRect();
          setCardDimensions({ width, height });
        }
      }
    };
    
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [children]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    const content = contentRef.current;
    
    if (container && content && deviceLayout === 'desktop') {
      const canScroll = content.scrollWidth > container.clientWidth;
      setShowControls(canScroll);
      
      // Center the content
      const scrollLeft = (content.scrollWidth - container.clientWidth) / 2;
      container.scrollLeft = scrollLeft;
    }
  }, [children, deviceLayout, cardDimensions]);

  const scroll = (direction) => {
    if (!scrollContainerRef.current) return;
    const container = scrollContainerRef.current;
    const scrollAmount = direction === 'left' ? -300 : 300;
    
    container.scrollBy({
      left: scrollAmount,
      behavior: 'smooth'
    });
  };

  const GhostCard = () => (
    <div
      className="bg-transparent shadow-lg hover:shadow-xl overflow-hidden
        transition-all duration-300 rounded-2xl cursor-pointer form-card"
      style={{
        width: `${cardDimensions.width}px`,
        height: `${cardDimensions.height}px`,
        background: "rgba(255, 255, 255, 0.2)",
        backdropFilter: "blur(10px)",
        border: '2px dashed rgba(156, 163, 175, 0.5)'
      }}
      onClick={onGhostCardClick}
    >
      <div className="h-full flex items-center justify-center">
        <div className="text-gray-400 flex flex-col items-center">
          <span className="text-4xl mb-2">+</span>
          <span className="text-sm">Add New</span>
        </div>
      </div>
    </div>
  );

  if (deviceLayout === 'mobile') {
    return (
      <div className="w-full flex flex-col items-center gap-4 py-4">
        {React.Children.map(children, (child) => (
          <div className="w-full">{child}</div>
        ))}
        {showGhostCard && (!maxItems || currentItemsCount < maxItems) && (
          <div className="flex justify-center w-full">
            <GhostCard />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="relative w-full">
      {/* {showControls && (
        <>
          <button
            onClick={() => scroll('left')}
            className="absolute left-2 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white shadow-lg rounded-full p-3"
            aria-label="Scroll left"
          >
            ←
          </button>
          <button
            onClick={() => scroll('right')}
            className="absolute right-2 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white shadow-lg rounded-full p-3"
            aria-label="Scroll right"
          >
            →
          </button>
        </>
      )} */}
      
      <div 
        ref={scrollContainerRef}
        className="w-full overflow-x-auto scrollbar-hide py-4"
        style={{
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        <div
          ref={contentRef}
          className="flex items-center"
          style={{
            gap: `${cardSpacing}px`,
            width: 'max-content',
            margin: '0 auto',
            padding: '0 64px',
          }}
        >
          {React.Children.map(children, (child) => (
            <div className="form-card">
              {child}
            </div>
          ))}
          
          {!disable && showGhostCard && (!maxItems || currentItemsCount < maxItems) && (
            <GhostCard />
          )}
        </div>
      </div>
    </div>
  );
};

export default HorizontalFormWrapper;