// import React from "react";
// import Select from "react-select";
// import { useField } from "formik";

// interface DropdownProps {
//   label?: string;
//   required?: boolean;
//   options: { value: string; label: string }[]; // react-select options
//   name: string;
//   placeHolder: string;
//   backgroundColor?: string;
//   isMulti?: boolean; // To enable multi-selection
//   labelClassNames?: string;
//   customHeight?: string; // New prop for custom height
//   controlCustomStyle?: any;
//   placeholderCustomStyle?: any;
// }

// const Dropdown: React.FC<DropdownProps> = ({
//   label,
//   options,
//   name,
//   placeHolder,
//   required,
//   backgroundColor = "white",
//   isMulti = false,
//   labelClassNames,
//   customHeight = "37px",
//   controlCustomStyle,
//   placeholderCustomStyle
// }) => {
//   const [field, meta, helpers] = useField(name);
//   const { setValue } = helpers;

//   return (
//     <div className="flex flex-col gap-1 w-full">
//       {/* Label */}
//       {!!label && (
//         <label
//           className={`block text-white ${labelClassNames || ""}`}
//           style={{
//             color: "white",
//             ...(!!customHeight && { fontSize: `calc(${customHeight} / 3)` }), // Adjust label font size based on customHeight
//           }}
//         >
//           {label}
//           {required && <span className="text-red-700">*</span>}
//         </label>
//       )}

//       {/* Select Dropdown */}
//       <div
//         style={{
//           backgroundColor,
//         }}
//         className="w-full border border-solid border-black-900 rounded-lg"
//       >
//         <Select
//           menuPlacement="top"
//           options={options}
//           isMulti={isMulti} // Enable multi-selection
//           onChange={(selectedOptions) =>
//             setValue(
//               isMulti
//                 ? (selectedOptions as { value: string; label: string }[]).map((option) => option.value) // Array of selected values
//                 : (selectedOptions as { value: string; label: string })?.value || "" // Single value
//             )
//           }
//           placeholder={placeHolder}
//           styles={{
//             control: (base) => ({
//               ...base,
//               backgroundColor,
//               borderRadius: "0.5rem", // Reduced border radius
//               ...(!!customHeight && {
//                 height: customHeight, // Customizable height
//                 minHeight: customHeight, // Ensure minimum height matches customHeight
//                 padding: `0 calc(${customHeight} / 8)`, // Reduced padding based on customHeight
//               }),
//               border: "0px", // Removed border
//               ...(!!controlCustomStyle ? controlCustomStyle : {}),
//             }),
//             placeholder: (base) => ({
//               ...base,
//               color: "#a1a1a1", // Lighter placeholder text color
//               ...(!!customHeight && { fontSize: `calc(${customHeight} / 2.5)` }), // Adjust font size based on customHeight
//               textOverflow: "ellipsis", // Prevents overflow in long text
//               whiteSpace: "nowrap", // Ensures placeholder text doesn't break
//               overflow: "hidden", // Hides overflow text
//               ...(!!placeholderCustomStyle ? placeholderCustomStyle : {}),
//             }),
//             menu: (base) => ({
//               ...base,
//               maxHeight: "400px", // Prevent overflow of dropdown menu
//               overflowY: "auto", // Scroll if there are too many options
//               scrollbarWidth: "thin",
//               msOverflowStyle: "auto",
//               zIndex: 999,
//               "::-webkit-scrollbar": {
//                 width: "6px",
//                 height: "6px",
//               },
//               "::-webkit-scrollbar-thumb": {
//                 background: "#c1c1c1",
//                 borderRadius: "10px",
//               },
//               "::-webkit-scrollbar-track": {
//                 background: "#f1f1f1",
//                 borderRadius: "10px",
//               },
//             }),
//             multiValue: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 margin: `0 calc(${customHeight} / 10)`, // Compact spacing for multi-select values
//                 padding: `0 calc(${customHeight} / 10)`, // Compact padding for selected items
//               }),
//             }),
//             multiValueLabel: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 fontSize: `calc(${customHeight} / 2.5)`, // Adjust label font size based on customHeight
//               }),
//             }),
//             multiValueRemove: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 fontSize: `calc(${customHeight} / 2.5)`, // Adjust remove button size based on customHeight
//               }),
//             }),
//           }}
//           value={
//             isMulti
//               ? options.filter((option) => field?.value?.includes(option.value)) // Sync multi-select values
//               : options.find((option) => option.value === field.value) // Sync single value
//           }
//           isClearable // Optional: Allows clearing the selection
//         />

//         {/* Error Message */}
//         {meta.touched && meta.error && (
//           <div className="text-red-600 text-xs mt-1">{meta.error}</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Dropdown;

// import React from "react";
// import Select from "react-select";
// import { useField } from "formik";

// interface DropdownProps {
//   label?: string;
//   required?: boolean;
//   options: { value: string; label: string }[]; // react-select options
//   name: string;
//   placeHolder: string;
//   backgroundColor?: string;
//   isMulti?: boolean; // To enable multi-selection
//   labelClassNames?: string;
//   customHeight?: string; // New prop for custom height
//   controlCustomStyle?: any;
//   placeholderCustomStyle?: any;
// }

// const Dropdown: React.FC<DropdownProps> = ({
//   label,
//   options,
//   name,
//   placeHolder,
//   required,
//   backgroundColor = "white",
//   isMulti = false,
//   labelClassNames,
//   customHeight = "37px",
//   controlCustomStyle,
//   placeholderCustomStyle
// }) => {
//   const [field, meta, helpers] = useField(name);
//   const { setValue } = helpers;

//   return (
//     <div className="flex flex-col gap-1 w-full">
//       {/* Label */}
//       {!!label && (
//         <label
//           className={`block text-white ${labelClassNames || ""}`}
//           style={{
//             color: "white",
//             ...(!!customHeight && { fontSize: `calc(${customHeight} / 3)` }), // Adjust label font size based on customHeight
//           }}
//         >
//           {label}
//           {required && <span className="text-red-700">*</span>}
//         </label>
//       )}

//       {/* Select Dropdown */}
//       <div
//         style={{
//           backgroundColor,
//         }}
//         className="w-full border border-solid border-black-900 rounded-lg"
//       >
//         <Select
//           menuPlacement="top"
//           options={options}
//           isMulti={isMulti} // Enable multi-selection
//           onChange={(selectedOptions) =>
//             setValue(
//               isMulti
//                 ? (selectedOptions as { value: string; label: string }[]).map((option) => option.value) // Array of selected values
//                 : (selectedOptions as { value: string; label: string })?.value || "" // Single value
//             )
//           }
//           placeholder={placeHolder}
//           styles={{
//             control: (base) => ({
//               ...base,
//               backgroundColor,
//               borderRadius: "0.5rem",
//               ...(!!customHeight && {
//                 height: customHeight,
//                 minHeight: customHeight,
//                 padding: `0 calc(${customHeight} / 8)`,
//               }),
//               border: "0px",
//               ...(!!controlCustomStyle ? controlCustomStyle : {}),
//             }),
//             placeholder: (base) => ({
//               ...base,
//               color: "#a1a1a1",
//               ...(!!customHeight && { fontSize: `calc(${customHeight} / 2.5)` }),
//               textOverflow: "ellipsis",
//               whiteSpace: "nowrap",
//               overflow: "hidden",
//               ...(!!placeholderCustomStyle ? placeholderCustomStyle : {}),
//             }),
//             menu: (base) => ({
//               ...base,
//               backgroundColor: "#333", // Dark background for menu
//               maxHeight: "400px",
//               overflowY: "auto",
//               scrollbarWidth: "thin",
//               msOverflowStyle: "auto",
//               zIndex: 999,
//               "::-webkit-scrollbar": {
//                 width: "6px",
//                 height: "6px",
//               },
//               "::-webkit-scrollbar-thumb": {
//                 background: "#c1c1c1",
//                 borderRadius: "10px",
//               },
//               "::-webkit-scrollbar-track": {
//                 background: "#f1f1f1",
//                 borderRadius: "10px",
//               },
//             }),
//             option: (base, { isSelected, isFocused }) => ({
//               ...base,
//               backgroundColor: isSelected ? "#555" : isFocused ? "#444" : "#333",
//               color: "#fff", // White text for options
//               padding: "10px",
//               cursor: "pointer",
//               ":hover": {
//                 backgroundColor: "#555", // Darker gray on hover
//               },
//             }),
//             multiValue: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 margin: `0 calc(${customHeight} / 10)`,
//                 padding: `0 calc(${customHeight} / 10)`,
//               }),
//             }),
//             multiValueLabel: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 fontSize: `calc(${customHeight} / 2.5)`,
//               }),
//             }),
//             multiValueRemove: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 fontSize: `calc(${customHeight} / 2.5)`,
//               }),
//             }),
//           }}
//           value={
//             isMulti
//               ? options.filter((option) => field?.value?.includes(option.value))
//               : options.find((option) => option.value === field.value)
//           }
//           isClearable
//         />

//         {/* Error Message */}
//         {meta.touched && meta.error && (
//           <div className="text-red-600 text-xs mt-1">{meta.error}</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Dropdown;

// import React from "react";
// import Select from "react-select";
// import { useField } from "formik";

// interface DropdownProps {
//   label?: string;
//   required?: boolean;
//   options: { value: string; label: string }[]; // react-select options
//   name: string;
//   placeHolder: string;
//   backgroundColor?: string;
//   isMulti?: boolean; // To enable multi-selection
//   labelClassNames?: string;
//   customHeight?: string; // New prop for custom height
//   controlCustomStyle?: any;
//   placeholderCustomStyle?: any;
// }

// const Dropdown: React.FC<DropdownProps> = ({
//   label,
//   options,
//   name,
//   placeHolder,
//   required,
//   backgroundColor = "white",
//   isMulti = false,
//   labelClassNames,
//   customHeight = "37px",
//   controlCustomStyle,
//   placeholderCustomStyle
// }) => {
//   const [field, meta, helpers] = useField(name);
//   const { setValue } = helpers;

//   return (
//     <div className="flex flex-col gap-1 w-full">
//       {/* Label */}
//       {!!label && (
//         <label
//           className={`block text-white ${labelClassNames || ""}`}
//           style={{
//             color: "white",
//             ...(!!customHeight && { fontSize: `calc(${customHeight} / 3)` }), // Adjust label font size based on customHeight
//           }}
//         >
//           {label}
//           {required && <span className="text-red-700">*</span>}
//         </label>
//       )}

//       {/* Select Dropdown */}
//       <div
//         style={{
//           backgroundColor,
//         }}
//         className="w-full border border-solid border-black-900 rounded-lg"
//       >
//         <Select
//           menuPlacement="top"
//           options={options}
//           isMulti={isMulti} // Enable multi-selection
//           onChange={(selectedOptions) =>
//             setValue(
//               isMulti
//                 ? (selectedOptions as { value: string; label: string }[]).map((option) => option.value) // Array of selected values
//                 : (selectedOptions as { value: string; label: string })?.value || "" // Single value
//             )
//           }
//           placeholder={placeHolder}
//           styles={{
//             control: (base) => ({
//               ...base,
//               backgroundColor,
//               borderRadius: "0.5rem", // Reduced border radius
//               ...(!!customHeight && {
//                 height: customHeight, // Customizable height
//                 minHeight: customHeight, // Ensure minimum height matches customHeight
//                 padding: `0 calc(${customHeight} / 8)`, // Reduced padding based on customHeight
//               }),
//               border: "0px", // Removed border
//               ...(!!controlCustomStyle ? controlCustomStyle : {}),
//             }),
//             placeholder: (base) => ({
//               ...base,
//               color: "#a1a1a1", // Lighter placeholder text color
//               ...(!!customHeight && { fontSize: `calc(${customHeight} / 2.5)` }), // Adjust font size based on customHeight
//               textOverflow: "ellipsis", // Prevents overflow in long text
//               whiteSpace: "nowrap", // Ensures placeholder text doesn't break
//               overflow: "hidden", // Hides overflow text
//               ...(!!placeholderCustomStyle ? placeholderCustomStyle : {}),
//             }),
//             menu: (base) => ({
//               ...base,
//               maxHeight: "400px", // Prevent overflow of dropdown menu
//               overflowY: "auto", // Scroll if there are too many options
//               scrollbarWidth: "thin",
//               msOverflowStyle: "auto",
//               zIndex: 999,
//               backgroundColor: "#2a2a2a", // Dark background color for dropdown
//               "::-webkit-scrollbar": {
//                 width: "6px",
//                 height: "6px",
//               },
//               "::-webkit-scrollbar-thumb": {
//                 background: "#c1c1c1",
//                 borderRadius: "10px",
//               },
//               "::-webkit-scrollbar-track": {
//                 background: "#f1f1f1",
//                 borderRadius: "10px",
//               },
//             }),
//             option: (base, { isFocused, isSelected }) => ({
//               ...base,
//               textAlign: "center", // Center-align option text
//               backgroundColor: isSelected ? "#ffcc00" : isFocused ? "#444" : "#2a2a2a", // Custom background colors
//               color: isSelected ? "#000" : "#fff", // Text color
//               cursor: "pointer",
//             }),
//             multiValue: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 margin: `0 calc(${customHeight} / 10)`, // Compact spacing for multi-select values
//                 padding: `0 calc(${customHeight} / 10)`, // Compact padding for selected items
//               }),
//             }),
//             multiValueLabel: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 fontSize: `calc(${customHeight} / 2.5)`, // Adjust label font size based on customHeight
//               }),
//             }),
//             multiValueRemove: (base) => ({
//               ...base,
//               ...(!!customHeight && {
//                 fontSize: `calc(${customHeight} / 2.5)`, // Adjust remove button size based on customHeight
//               }),
//             }),
//           }}
//           value={
//             isMulti
//               ? options.filter((option) => field?.value?.includes(option.value)) // Sync multi-select values
//               : options.find((option) => option.value === field.value) // Sync single value
//           }
//           isClearable // Optional: Allows clearing the selection
//         />

//         {/* Error Message */}
//         {meta.touched && meta.error && (
//           <div className="text-red-600 text-xs mt-1">{meta.error}</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Dropdown;

import React from "react";
import Select from "react-select";
import { useField } from "formik";

interface DropdownProps {
  label?: string;
  required?: boolean;
  options: { value: string; label: string }[];
  name: string;
  placeHolder: string;
  isMulti?: boolean;
  labelClassNames?: string;
  customHeight?: string;
  controlCustomStyle?: any;
  placeholderCustomStyle?: any;
  disable?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  name,
  placeHolder,
  required,
  isMulti = false,
  labelClassNames,
  customHeight = "37px",
  controlCustomStyle,
  placeholderCustomStyle,
  disable
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  return (
    <div className="flex flex-col gap-1 w-full">
      {!!label && (
        <label
          className={`block ${labelClassNames || ""}`}
          style={{
            // color: "white",
            ...(!!customHeight && { fontSize: `calc(${customHeight} / 3)` }),
          }}
        >
          {label}
          {required && <span className="text-red-700">*</span>}
        </label>
      )}

      <div className="w-full border border-solid border-black-900 rounded-lg">
        <Select
          isDisabled={disable}
          menuPlacement="top"
          options={options}
          isMulti={isMulti}
          onChange={(selectedOptions) =>
            setValue(
              isMulti
                ? (selectedOptions as { value: string; label: string }[]).map(
                  (option) => option.value
                )
                : (selectedOptions as { value: string; label: string })?.value ||
                ""
            )
          }
          placeholder={placeHolder}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }), 
            control: (base) => ({
              ...base,
              // background: "linear-gradient(120deg, #C7366B 10%, #9F33AC 50%, #8532D7 90%)",
              borderRadius: "0.5rem",
              ...(!!customHeight && {
                height: customHeight,
                minHeight: customHeight,
                padding: `0 calc(${customHeight} / 8)`,
              }),
              border: "0px",
              ...(!!controlCustomStyle ? controlCustomStyle : {}),
            }),
            placeholder: (base) => ({
              ...base,
              // color: "#ffffff",
              // fontWeight: "bold",
              // textAlign: "center",
              ...(!!customHeight && { fontSize: `calc(${customHeight} / 2.5)` }),
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              ...(!!placeholderCustomStyle ? placeholderCustomStyle : {}),
            }),
            // menu: (base) => ({
            //   ...base,
            //   maxHeight: "400px",
            //   overflowY: "auto",
            //   scrollbarWidth: "thin",
            //   msOverflowStyle: "auto",
            //   zIndex: 999,
            //   // background: "linear-gradient(120deg, #C7366B 10%, #9F33AC 50%, #8532D7 90%)",
            //   background: "transparent",
            //   border: "2px solid black",
            //   borderRadius: "8px",
            //   padding: "5px",
            //   "::-webkit-scrollbar": {
            //     width: "6px",
            //     height: "6px",
            //   },
            //   "::-webkit-scrollbar-thumb": {
            //     background: "#c1c1c1",
            //     borderRadius: "10px",
            //   },
            //   "::-webkit-scrollbar-track": {
            //     background: "#f1f1f1",
            //     borderRadius: "10px",
            //   },
            // }),
            menu: (base) => ({
              ...base,
              maxHeight: "400px",
              overflowY: "auto",
              scrollbarWidth: "thin",
              msOverflowStyle: "auto",
              zIndex: 999,
              background: "transparent", // Fully transparent background
              border: "2px solid #494949",
              borderRadius: "8px",
              backdropFilter: "blur(20px)", // Adds a slight blur effect for better readability
              boxShadow: "none", // Removes any default shadow
              "::-webkit-scrollbar": {
                width: "6px",
                height: "6px",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#c1c1c1",
                borderRadius: "10px",
              },
              "::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
            }),
            option: (base, { isFocused, isSelected }) => ({
              // ...base,
              // textAlign: "center",
              // backgroundColor: isSelected ? "#000000bd" : isFocused ? "#0000002b" : "transparent",
              // color: isSelected ? "#f1f1f1" : isFocused ? "#121212" : "#000000" ,
              // cursor: "pointer",
              ...base,
              textAlign: "center",
              background: isSelected
                ? "rgba(0, 0, 0, 0.75)" // Darker black for selected option
                : isFocused
                  ? "rgba(0, 0, 0, 0.4)" // Lighter black glass effect on hover
                  : "transparent",
              color: isSelected ? "#f1f1f1" : isFocused ? "#ffffff" : "#000000",
              cursor: "pointer",
              backdropFilter: isFocused ? "blur(8px)" : "none", // Adds blur effect for glassmorphism
              // borderRadius: "5px",
              transition: "background 0.3s ease, color 0.3s ease", // Smooth transition effect
            }),
            multiValue: (base) => ({
              ...base,
              ...(!!customHeight && {
                margin: `0 calc(${customHeight} / 10)`,
                padding: `0 calc(${customHeight} / 10)`,
              }),
            }),
            multiValueLabel: (base) => ({
              ...base,
              ...(!!customHeight && {
                fontSize: `calc(${customHeight} / 2.5)`,
              }),
            }),
            multiValueRemove: (base) => ({
              ...base,
              ...(!!customHeight && {
                fontSize: `calc(${customHeight} / 2.5)`,
              }),
            }),
          }}
          value={
            isMulti
              ? options.filter((option) => field?.value?.includes(option.value))
              : options.find((option) => option.value === field.value)
          }
          isClearable
        />       
      </div>
      {meta.touched && meta.error && (
        <div className="text-red-600 text-xs mt-1">
          {Array.isArray(meta.error) ? meta.error.join(", ") : meta.error}
        </div>
      )}  
    </div>
  );
};

export default Dropdown;