import React from 'react';
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom/client';

const AutoSaveSpinner = () => {
  return (
    <div style={{ width: "120px", height: "120px", position: "relative" }}>
      {/* Spinning Loader */}
      <svg
        viewBox="0 0 50 50"
        className="w-full h-full"
        style={{
          animation: "rotate 1.5s linear infinite",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <defs>
          <linearGradient id="spinnerGradient" x1="0%" y1="50%" x2="100%" y2="50%">
            <stop offset="0%" stopColor="rgba(255, 255, 255, 1)" stopOpacity="1" /> {/* Full vibrant */}
            <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" stopOpacity="0" /> {/* Fades out */}
          </linearGradient>
        </defs>
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke="url(#spinnerGradient)"
          strokeWidth="4"
          strokeLinecap="round"
          strokeDasharray="80"
          strokeDashoffset="0"
          style={{
            transformOrigin: "center",
          }}
        />
        <style>
          {`
            @keyframes rotate {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </svg>
    </div>
  );
};

export const showAutoSaveNotification = (onComplete) => {
  Swal.fire({
    html: ` 
      <div style=" 
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 48px 48px 48px 56px;
        width: 100%;
        gap: 32px;
        overflow: hidden;
      ">
        <div style=" 
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          flex: 1;
        ">
          <h2 style="
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 16px;
            color: white;
            line-height: 1.2;
          ">And..Don't Worry!</h2>
          <p style="
            font-size: 24px;
            color: white;
            margin: 0;
            line-height: 1.4;
          ">Your progress is automatically saved!</p>
        </div>
        <div id="auto-save-spinner"></div>
      </div>
    `,
    showConfirmButton: false,
    width: '600px',
    padding: 0,
    background: 'linear-gradient(135deg, #C7366B 0%, #9F33AC 50%, #8532D7 100%)',
    customClass: {
      popup: 'rounded-3xl border-none shadow-xl',
      container: 'overflow-hidden'
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOut animate__faster'
    },
    didOpen: () => {
      const spinnerContainer = document.getElementById('auto-save-spinner');
      if (spinnerContainer) {
        const root = ReactDOM.createRoot(spinnerContainer);
        root.render(<AutoSaveSpinner />);
      }

      setTimeout(() => {
        Swal.close();
        if (onComplete) onComplete();
      }, 3000);
    }
  });
};

export default AutoSaveSpinner;
