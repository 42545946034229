import React from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import HorizontalFormWrapper from "components/HorizontalFormWrapper";
import FormCard from "components/FormCard";
import ResetAndSubmit from "components/ResetAndSubmit";
import * as Yup from "yup";
import TextArea from "components/TextArea";

const SUBJECT_OPTIONS = [
  { value: "Telugu", label: "Telugu" },
  { value: "Math", label: "Math" },
  { value: "English", label: "English" },
  { value: "Science", label: "Science" },
  { value: "Social", label: "Social" },
  { value: "Hindi", label: "Hindi" },
  { value: "Others", label: "Others" },
];

const QUALIFICATION_OPTIONS = [
  { value: "B.Ed", label: "B.Ed" },
  { value: "M.Ed", label: "M.Ed" },
  { value: "Ph.D", label: "Ph.D" },
  { value: "M.Phil", label: "M.Phil" },
  { value: "B.Sc", label: "B.Sc" },
  { value: "M.Sc", label: "M.Sc" },
  { value: "B.A", label: "B.A" },
  { value: "M.A", label: "M.A" },
  { value: "B.Tech", label: "B.Tech" },
  { value: "M.Tech", label: "M.Tech" },
  { value: "Others", label: "Others" },
];

const validationSchema = Yup.object({
  faculties: Yup.array().of(
    Yup.object().shape({
      FACULTY_NAME: Yup.string().required("Faculty name is Required"),
      FACULTY_SUBJECT: Yup.array().min(1, "Atleast 1 Faculty subject is Required"),
      FACULTY_QUALIFICATION: Yup.string().required("Faculty qualification is Required"),
      FACULTY_EXPERIENCE: Yup.number().required("Faculty experience is Required"),
      // FACULTY_DESCRIPTION: Yup.string(),
      // FACULTY_IMAGE: Yup.string().required("Faculty image is Required"),
    })
  ),
})



const Faculties = ({ handleNavigate, resetFormData, titleAndDesNotRequired, disableForm }: { handleNavigate, resetFormData?: any, titleAndDesNotRequired?: boolean, disableForm?: boolean }) => {
  const maxForms = 5;

  const emptyFacultyData = {
    FACULTY_NAME: "",
    FACULTY_SUBJECT: [],
    FACULTY_QUALIFICATION: "",
    FACULTY_EXPERIENCE: "",
    FACULTY_DESCRIPTION: "",
    FACULTY_IMAGE: "",
  };

  const loadInitialData = () => {
    const savedData = JSON.parse(sessionStorage.getItem("faculties")) || [];
    return savedData.length ? savedData : [emptyFacultyData];
  };

  return (
    <FormWrapper
      childern={
        <Formik
          initialValues={{ faculties: loadInitialData() }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log("values.faculties ", values.faculties)
            sessionStorage.setItem("faculties", JSON.stringify(values.faculties));
            handleNavigate();
          }}
        >
          {({ values, handleSubmit, resetForm }) => (
            <Form onSubmit={handleSubmit} className="flex flex-col gap-1 w-full relative">

              {/* {values.faculties.length < maxForms && (
                <div className="absolute top-2 right-2 z-10">
                  <FieldArray name="faculties">
                    {({ push }) => (
                      <button
                        type="button"
                        onClick={() => push(emptyFacultyData)}
                        className="text-slate-700 font-bold text-[1vw] rounded-2xl border w-8 h-8 flex items-center justify-center hover:bg-slate-300 bg-[white]"
                      >
                        +
                      </button>
                    )}
                  </FieldArray>
                </div>
              )} */}


              <div className={`bg-onboardingBackground rounded-3xl text-white space-y-3 ${!!titleAndDesNotRequired ? "" : "p-6 border border-[2.5px] border-purple-gradientOBborder"}`}>
                {!titleAndDesNotRequired && <div className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */>
                  <div className="mx-2 w-[80%] text-center" /* style={{ color: "white" }} */>
                    <p className="text-sm font-bold mb-0.7">Empowering the Leaders of Tomorrow</p>
                    <p className="text-xs font-thin">
                      Upload your teacher's photos and showcase the inspiring faces behind the learning.
                      Highlight the educators shaping your student's future!
                    </p>
                  </div>
                  <p className="text-xs italic  text-gray-500 font-thin" /* style={{ color: "white" }} */>
                    (Maximum 5 Teacher's Details)
                  </p>
                </div>}

                {/* Insert Button Positioned Above the Forms */}


                <div className="rounded-2xl my-1 mx-1">
                  <FieldArray name="faculties">
                    {({ push, remove }) => (
                      <div className="w-full">
                        {/* For larger screens (Desktop or Tablet), use Horizontal FormWrapper */}
                        <HorizontalFormWrapper
                          cardSpacing={30}
                          showGhostCard={true}
                          onGhostCardClick={() => push(emptyFacultyData)}
                          maxItems={maxForms}
                          currentItemsCount={values.faculties.length}
                          disable={disableForm}
                        >
                          {values.faculties.map((faculty, index) => (
                            <FormCard
                              key={index}
                              cardWidthClass="min-w-[50vh]"
                              onDelete={values.faculties.length > 1 ? () => remove(index) : undefined}
                            >
                              <Field name={`faculties.${index}.FACULTY_IMAGE`} >
                                {({ field, form }) => (
                                  <ImageUpload
                                    disable={disableForm}
                                    labelClassNames="text-center font-bold text-[0.8vw] pb-2"
                                    imageValue={field.value}
                                    onChange={(item) => form.setFieldValue(`faculties.${index}.FACULTY_IMAGE`, item)}
                                    wantHDOption
                                    containerHeight="h-8"
                                  />
                                )}
                              </Field>
                              <ErrorMessage name={`faculties.${index}.FACULTY_IMAGE`} component="div" className="text-red-500 text-xs mt-1" />

                              <Input
                                disable={disableForm}
                                label="What's Their Name?"
                                name={`faculties.${index}.FACULTY_NAME`}
                                type="text"
                                placeHolder="Enter their name here"

                              />

                              <Dropdown
                                disable={disableForm}
                                label="What Subject Do They Teach?"
                                name={`faculties.${index}.FACULTY_SUBJECT`}
                                placeHolder="Select Subjects"
                                options={SUBJECT_OPTIONS}

                                isMulti
                                customHeight="35px"
                              />

                              <Dropdown
                                disable={disableForm}
                                label="Their Qualification"
                                name={`faculties.${index}.FACULTY_QUALIFICATION`}
                                placeHolder="Select Qualification"
                                options={QUALIFICATION_OPTIONS}

                                customHeight="35px"
                              />

                              <Input
                                disable={disableForm}
                                label="Years of Teaching Experience"
                                name={`faculties.${index}.FACULTY_EXPERIENCE`}
                                type="number"
                                placeHolder="Enter years of experience"

                              />

                              <TextArea
                                disabled={disableForm}
                                label="A Few Words About Them"
                                name={`faculties.${index}.FACULTY_DESCRIPTION`}
                                autoGenerate={true}
                                placeHolder="Optional"
                                customHeight="27px"
                              />
                            </FormCard>
                          ))}
                        </HorizontalFormWrapper>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>

              {/* Buttons Section */}
              {!disableForm && <ResetAndSubmit
                resetForm={() => {
                  resetForm();
                  resetFormData();
                }}
              />}
            </Form>
          )}
        </Formik>
      }
      formHeading={undefined}
    />
  );
};

export default Faculties;
