import React from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import TextArea from "components/TextArea";
import HorizontalFormWrapper from "components/HorizontalFormWrapper";
import FormCard from "components/FormCard";
import ResetAndSubmit from "components/ResetAndSubmit";
import * as Yup from "yup";

const validationSchema = Yup.object({
    materials: Yup.array().of(
        Yup.object({
            // MATERIAL_IMAGE: Yup.string().required("Image is required"),
            MATERIAL_NAME: Yup.string().required("Name is required"),
            MATERIAL_TYPE: Yup.string().required("Material Type is required"),
            MATERIAL_DESCRIPTION: Yup.string().required("Description is required"),
        })
    ),
});

const Brochu = ({ handleNavigate, resetFormData, titleAndDesNotRequired, disableForm }: { handleNavigate, resetFormData?: any, titleAndDesNotRequired?: boolean, disableForm?: boolean }) => {
    const maxForms = 5;

    const emptyBrochure = {
        MATERIAL_IMAGE: "",
        MATERIAL_TYPE: "",
        MATERIAL_NAME: "",
        MATERIAL_DESCRIPTION: "",
    };

    const MATERIAL_TYPE_OPTIONS = [
        { value: "brochure", label: "Brochure" },
        { value: "flyer", label: "Flyer" },
    ];

    const loadInitialData = () => {
        const savedData = JSON.parse(sessionStorage.getItem("materials")) || [];
        return savedData.length ? savedData : [emptyBrochure];
    };

    return (
        <FormWrapper
            childern={
                <Formik
                    initialValues={{ materials: loadInitialData() }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log("payload ",{
                            Institute: JSON.parse(sessionStorage.getItem("Institute")),
                            founders: JSON.parse(sessionStorage.getItem("founders")),
                            faculties: JSON.parse(sessionStorage.getItem("faculties")),
                            recognitions: JSON.parse(sessionStorage.getItem("recognitions")),
                            campus: JSON.parse(sessionStorage.getItem("campus")),
                            Chatbot: JSON.parse(sessionStorage.getItem("Chatbot")),
                            testimonials: JSON.parse(sessionStorage.getItem("testimonials")),
                            materials: values?.materials,
                        })
                        // console.log("values.materials ", values.materials)
                        sessionStorage.setItem("materials", JSON.stringify(values.materials));
                        handleNavigate(true)
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit} className="flex flex-col gap-1 w-full relative" noValidate>

                            {/* Insert Button Positioned Above the Forms */}
                            {/* {values.materials.length < maxForms && (
                                <div className="absolute top-2 right-2 z-10">
                                    <FieldArray name="materials">
                                        {({ push }) => (
                                            <button
                                                type="button"
                                                onClick={() => push(emptyBrochure)}
                                                className="text-slate-700 font-bold text-[1vw] rounded-2xl border w-8 h-8 flex items-center justify-center hover:bg-slate-300 bg-[white]"
                                            >
                                                +
                                            </button>
                                        )}
                                    </FieldArray>
                                </div>
                            )} */}

<div className={`bg-onboardingBackground rounded-3xl text-white space-y-3 ${!!titleAndDesNotRequired ? "" : "p-6 border border-[2.5px] border-purple-gradientOBborder"}`}>
                                {!titleAndDesNotRequired && <div className="flex flex-col gap-2 justify-center items-center" /* style={{ color: 'white' }} */>
                                    <div className="mx-2 w-[80%] text-center">
                                        <p className="text-sm font-bold mb-0.7">
                                            Brochures, Prospectuses & Flyers
                                        </p>
                                        <p className="text-xs font-thin">
                                            Have Materials Already? Let's Upload Them!
                                        </p>
                                    </div>
                                    <p className="text-xs italic text-gray-500 font-thin" /* style={{ color: "white" }} */>
                                        (Maximum 5 Brochures Details)
                                    </p>
                                </div>}



                                <div className="rounded-2xl my-1 mx-1">
                                    <FieldArray name="materials">
                                        {({ push, remove }) => (
                                            <div className="w-full">
                                                <HorizontalFormWrapper
                                                    cardSpacing={30}
                                                    showGhostCard={true}
                                                    onGhostCardClick={() => push(emptyBrochure)}
                                                    maxItems={maxForms}
                                                    currentItemsCount={values.materials.length}
                                                    disable={disableForm}
                                                >
                                                    {values.materials.map((leaderElement, index) => (
                                                        <FormCard
                                                            key={index}
                                                            onDelete={values.materials.length > 1 ? () => remove(index) : undefined}
                                                        >
                                                            <Field name={`materials.${index}.MATERIAL_IMAGE`}>
                                                                {({ field, form }) => (
                                                                    <ImageUpload
                                                                    disable={disableForm}
                                                                        labelClassNames="text-center font-bold text-[0.8vw] pb-2"
                                                                        imageValue={field.value}
                                                                        onChange={(item) => {
                                                                            form.setFieldValue(`materials.${index}.MATERIAL_IMAGE`, item);
                                                                        }}
                                                                        wantHDOption
                                                                        containerHeight="h-8"
                                                                    />
                                                                )}
                                                            </Field>
                                                            <ErrorMessage name={`materials.${index}.MATERIAL_IMAGE`} component="div" className="text-red-500 text-xs mt-1" />

                                                            <Input
                                                disable={disableForm}
                                                                label="Name of the Material"
                                                                name={`materials.${index}.MATERIAL_NAME`}
                                                                type="text"
                                                                placeHolder="Enter the name of the material here (e.g., The Learning Hub)"
                                                                required
                                                            />

                                                            <Dropdown
                                                disable={disableForm}
                                                                label="Material Type"
                                                                name={`materials.${index}.MATERIAL_TYPE`}
                                                                placeHolder="Select Material Type (e.g., Brochure, Flyer)"
                                                                options={MATERIAL_TYPE_OPTIONS}
                                                                required
                                                            />

                                                            <TextArea
                                                disabled={disableForm}
                                                                label="Description"
                                                                name={`materials.${index}.MATERIAL_DESCRIPTION`}
                                                                placeHolder=""
                                                                required
                                                                customHeight="60px"
                                                                autoGenerate={true}
                                                            />
                                                        </FormCard>
                                                    ))}
                                                </HorizontalFormWrapper>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>

                            {/* Buttons Section */}
                            {!disableForm && <ResetAndSubmit
                                submitLabel="Preview & Submit"
                                resetForm={() => {
                                    resetForm();
                                    resetFormData();
                                }}
                            />}
                        </Form>
                    )}
                </Formik>
            }
            formHeading={undefined}
        />
    );
};

export default Brochu;